@import 'assets/less/variables';

.milestone {
  display: flex;
  align-items: center;
  width: 164px;
  height: 16px;
  margin-bottom: 4px;

  .screen-sm({
    width: unset;
  });

  svg {
    margin-right: 8px;
  }

  span {
    font-size: 12px;
    line-height: 20px;
    color: @gray-1;

    .screen-xs({
      font-size: 11px;
    });
  }

  &__complete {
    svg {
      path {
        fill: @primary;
      }
    }

    span {
      color: @primary;
      word-wrap: break-word;
    }
  }
}

.milestones {
  display: flex;
  margin-left: 12px;

  &__vertical {
    flex-direction: column;
  }

  .screen-sm({
    flex-direction: column;
  });

  .screen-xs({
    width: unset;
  });
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';