@import 'assets/less/variables';

.vacancy-agency {
  width: 400px;
  border: 1px solid rgba(207, 212, 221, 0.5);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 24px;

  &__title {
    display: flex;

    h3 {
      font-weight: 600;
      font-size: 16px;
      margin: 0;
    }

    p {
      font-weight: 600;
      font-size: 12px;
      color: @gray-1;
      margin: 0;
    }
  }

  &__description {
    margin: 0;

    &__rich-text {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: @gray-4;
    }
  }

  &:not(:last-child) {
    margin-bottom: 0px;
  }

  .ant-tag {
    border: none;
    padding: 0;
    margin-top: 12px;
    background: #fff;
    font-weight: 600;
    font-size: 14px;
    white-space: initial;
    display: inline-flex;
    align-items: center;
    color: @gray-4;

    &:last-of-type {
      margin-bottom: 16px;
    }

    svg {
      margin-right: 5px;
      min-width: 24px;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';