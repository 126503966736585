@import 'assets/less/variables';
@import 'assets/less/colors';

.vacancy-dropdown-menu {
  .ant-dropdown-menu {
    width: 143px;
    border-radius: @border-radius-5;
    background-color: #fff;
  }

  .ant-menu-item {
    color: @gray-4;
  }

  &__spin {
    margin-left: 8px;
    svg {
      path {
        color: @dark;
      }
    }
  }
}

.vacancy-block {
  width: 348px;
  height: 240px;
  display: flex;

  .screen-sm({
    width: 100%;
  });

  &__wrapper {
    position: relative;
    width: 100%;
    border: @border-style-1;
    box-sizing: border-box;
    border-radius: 16px;
    background: #fff;
    padding: 16px;
  }

  &__dropdown-wrapper {
    width: fit-content;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__dropdown-trigger {
    width: unset;
    height: unset;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    svg {
      path {
        fill: @gray-3;
      }
    }
  }

  &__button-container {
    display: flex;
    flex-flow: row;
    gap: 8px;
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;

    &__send-message {
      align-items: center;
      display: flex;
      gap: 8px;
      border: none;
      background: @blue-200;
      color: @primary;
    }
  }

  &__position {
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__facility {
    font-weight: 600;
    font-size: 12px;
    color: @gray-1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__info {
    padding: 0;
    margin: 16px 0;
    list-style-type: none;
    li {
      color: @gray-4;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 5px;
      }

      svg {
        margin-right: 5px;
        min-width: 24px;
        height: 20px;
      }
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ant-btn-primary {
    width: 100%;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';