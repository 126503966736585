@import 'assets/less/variables';

.professions-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  margin-bottom: 60px;

  .screen-lg({
    margin-bottom: 35px;
    gap: 24px;
  });

  &__title {
    text-align: center;
    color: @dark;
    font-family: Poppins;
    font-size: 28px;
    font-weight: 700;
    line-height: 44px;

    .screen-lg({
      font-size: 24px;
    });

    &--highlighted {
      color: @primary;
    }
  }

  &__professions {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 40px;
    row-gap: 16px;

     .screen-lg({
       gap: 16px;
       row-gap: 12px;
    });

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 6px;
      border-radius: 4px;
      background: @primary-light-4;
      color: @dark;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;

      .screen-lg({
        font-size: 12px;
      });
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';