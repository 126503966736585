@import 'assets/less/variables';

.recommendations-wrapper {
  border-radius: @border-radius-2;
  border: 1px solid @primary-light-2;
  background-color: #fff;

  &__empty-state {
    height: calc(100vh - 200px);

    &__button {
      color: @primary;
      padding: 8px 32px;
      display: flex;
      gap: 8px;
      align-items: center;

      &:not(:hover) {
        background-color: @primary-light-4;
      }

      svg {
        path {
          fill: @primary;
        }
      }
    }
  }

  &__description {
    display: flex;
    justify-content: space-between;
    padding: 24px;

    &__title {
      font-size: 18px;
      font-weight: 600;
      line-height: 156%;
      margin: 0;
    }

    &__schedule {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 18px;
      font-weight: 600;
      line-height: 156%;

      &__text {
        font-size: 14px;
        font-weight: 500;
        line-height: 100%;
      }
    }
  }

  &__table {
    &__column {
      &__name {
        display: flex;
        align-items: center;
        gap: 8px;

        &__link {
          font-size: 14px;
          font-weight: normal;

          &:not(:hover) {
            color: @dark;
          }
        }
      }

      &__profession {
        display: flex;
        flex-flow: column;

        span:first-child {
          font-weight: 500;
        }
      }

      &__shortlist {
        margin-left: auto;
      }
    }

    .ant-table-thead {
      .ant-table-cell {
        background: #fff;
        font-size: 12px;
        font-weight: 700;
        color: @gray-1;
      }
      .ant-table-cell::before {
        display: none;
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        height: 48px;
        font-size: 14px;
      }
    }

    .ant-table-pagination {
      margin-right: 24px !important;
    }
  }

  &__candidate-modal {
    &__action-button {
      display: flex;
      gap: 8px;
      color: @gray-4 !important;
      border: 1px solid @gray-4 !important;
      padding: 8px 6px;

      &:hover {
        color: @primary-medium !important;
        border: 1px solid @primary-medium !important;
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';