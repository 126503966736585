@import 'assets/less/colors';

.modal {
  &__title {
    display: flex;
    gap: 8px;
    align-items: center;
    height: 24px;
    font-weight: 600;
    color: @grey-900;

    > svg:first-child {
      width: 24px;
      height: 24px;

      path {
        fill: currentColor;
      }
    }
  }

  &__subtitle {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: @grey-500;
  }

  .ant-modal-content {
    padding: 24px;
    border-radius: 16px;
  }

  .ant-modal-header {
    padding: 0;
    border: unset;
  }

  .ant-modal-close {
    top: unset;
    right: 24px;

    .ant-modal-close-x {
      &, & > svg {
        width: 24px;
        height: 24px;
      }

      > svg {
        display: block;

        path {
          fill: @grey-900;
        }
      }
    }
  }

  .ant-modal-body {
    padding: 24px 0px;
  }

  .ant-modal-footer {
    display: flex;
    gap: 8px;
    padding: 0;
    border: unset;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';