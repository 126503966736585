@import 'assets/less/variables';

.international-landing {
  &__background {
    position: absolute;
    right: 0;
    height: -webkit-fill-available;
    z-index: -1;

    .screen-lg({
      width: -webkit-fill-available;
      height: 333px;
      top: 0px;
      left: 55px;
      });
  }

  &__wrapper {
    margin: 0 auto;
    max-width: 1240px;
    padding: 32px 32px 0 32px;

    .screen-lg({
      padding: 0 16px 0 16px;
    });
  }

  &__primary-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 32px;
    border-radius: 4px;
    background: @light;
    color: @primary;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 148%;

    &__link {
      display: flex;
      align-items: center;
    }

    svg {
      margin-left: 16px;
      width: 24px;
      height: 24px;
    }
  }

  &__featured-facilities {
    margin-bottom: 24px;

    span {
      line-height: 124%;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';