@import 'assets/less/variables';

.empty-card {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: none;
    padding: 44px;
  }

  &__requisites {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    text-align: center;
    margin: 12px 0 2px 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: @dark;
  }

  &__description {
    text-align: center;
    max-width: 400px;
    font-size: 14px;
    line-height: 24px;
    color: @gray-1;
    margin-bottom: 24px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';