@import 'assets/less/variables';

.auth-header {
  position: relative;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  height: 80px;

  &__logo-wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: auto;
  }

  &__logo {
    .screen-sm({
      width: 124px;
      height: 18px;
    });
  }

  &__contacts {
    display: flex;

    .screen-md({
      display: none;
      flex-direction: column;
    });
  }

  &__link {
    margin-left: 32px;

    .screen-md({
      margin: 0 0 16px 0;
    });
  }

  .screen-md({
    height: auto;
    padding: 24px 0;
  });

  .screen-sm({
    flex-direction: column;
    justify-content: flex-start;
  });
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';