@import 'assets/less/variables';
@import 'assets/less/colors';

.speciality-experience-form {
  &__parent-form-item {
    margin-bottom: 0;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    margin-bottom: 16px;

    &--bordered {
      border-bottom: 1px solid @grey-300;
    }

    &__speciality-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: @gray-4;
      font-size: 16px;
      font-weight: 600;
      line-height: 148%;
    }

    &__experience-in-years {
      margin-bottom: 0;

      .ant-form-item-control {
        align-items: flex-end;
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';