@import 'assets/less/variables';

.interactive-header {
  margin-bottom: 90px;
  display: flex;
  justify-content: space-between;

  .screen-lg({
    margin-bottom: 62px;
    flex-direction: column;
    justify-content: center;
  });

  &__wrapper {
    width: 538px;

    .screen-lg({
      width: unset;
      margin-bottom: 56px;
    });

    div {
      display: flex;

      .screen-lg({
        flex-direction: column;
        align-items: center;
      });
    }
  }

  &__actions {
    flex-direction: row;
    gap: 12px;
  }

  &__banner {
    .screen-lg({
      margin-top: -24px;
    });

    img {
      max-width: 600px;
      height: 420px;

      .screen-lg({
        height: auto;
        width: 100%;
      });
    }
  }

  h1 {
    font-family: @font-family-secondary;
    font-weight: 800;
    font-size: 52px;
    line-height: 74px;
    color: @dark;

    .screen-lg({
      font-size: 32px;
      line-height: 40px;
      text-align: center;
    });
  }

  &__header-margin {
    margin: 48px 0;

    .screen-lg({
      margin: 0 0 48px 0;
    });
  }

  &__description-margin {
    margin: 48px 0 24px 0;

    .screen-lg({
      margin: 0 0 48px 0;
    });
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: @gray-1;
    margin-bottom: 38px;

    .screen-lg({
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      margin-bottom: 48px
    });
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';