@import 'assets/less/variables';

.benefits-card {
  &__iconed-detail {
    display: flex;
    align-items: center;
    gap: 8px;

    &:not(:first-child) {
      margin-top: 12px;
    }

    &__icon-before {
      min-width: 24px;
      margin-bottom: 6px;

      path {
        fill: @gray-4;
      }
    }

    &__attrs {
      display: flex;
      align-items: flex-start;
      gap: 4px;

      &__icon-after {
        path {
          fill: @primary !important;
        }
      }

      &__flights &__icon-after {
        path {
          stroke: @primary;
          fill: @light;
        }
      }

      &__value {
        display: inline-block;
        font-size: 14px;
        line-height: 24px;
        color: @gray-4;
        margin: 0;
        margin-top: 1px;
        font-weight: 400;
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';