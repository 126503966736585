@import 'assets/less/variables';

.hp-user-sidebar {
  -webkit-transition: width 0.3s ease-out;
  -moz-transition: width 0.3s ease-out;
  -o-transition: width 0.3s ease-out;
  transition: width 0.3s ease-out;
  width: 232px;
  border-right: 1px solid @gray-6;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background: #fff;
  overflow-y: scroll;
  max-height: 100%;
  scrollbar-width: none;

  &__collapsed {
    width: 68px;
  }

  &__collapse-link {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 168px;
    margin: 0 16px 0 25px;
    padding: 8px;
    border: none;
    text-align: unset;
    border-radius: 6px;
    background: @primary-light-4;

    &__collapsed {
      margin-left: 16px;
      width: auto;
    }

    &:focus {
      background: @primary-light-4;
    }

    &__text {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .ant-menu-inline {
    border-right: none;
  }

  &__logo-wrapper {
    margin: 20px 20px 8px;
    background: none;

    &--expanded {
      width: unset;
    }
  }

  &__logo {
    width: 191px;
    height: 32px;
    margin-right: 16px;

    &--expanded {
      width: 32px;
      height: 32px;
      margin-right: 0;
    }
  }

  &__group {
    &__nav-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__message-counter {
        .ant-badge-count {
          background-color: @orange;

          .ant-scroll-number-only-unit {
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
          }
        }
      }
    }

    &__collapsed {
      div.ant-menu-item-group-title {
        margin-left: 0;
        padding-left: 5px;
      }
    }
    .ant-menu-item-group-title {
      font-size: 12px;
      line-height: 20px;
      color: @gray-3;
      margin: 0 8px;
    }
  }

  .ant-menu-item {
    font-size: 14px;
    font-weight: bold;
    a {
      color: @gray-1;
    }

    &:hover {
      color: @gray-1;
    }

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: @gray-1;
      }
    }
  }

  .ant-menu-item-selected {
    pointer-events: none;
    a {
      color: @primary;
    }
    svg {
      path {
        fill: @primary;
      }
    }
    &:before {
      content: '';
      background-color: @primary;
      height: 40px;
      width: 5px;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 0 12px 12px 0;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';