@import 'assets/less/colors';

.inbox-thread-card {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 12px;
  margin-top: 8px;
  border: none;
  background-color: @white;

  &:not(&--active):hover {
    background-color: @blue-100;
    cursor: pointer;
  }

  &--active {
    background-color: @blue-100;
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
      width: 2px;
      background-color: @blue;
    }
  }

  &__details {
    display: flex;
    flex-flow: column;
    justify-content: center;
    flex: 1 0 auto;
    gap: 4px;
    user-select: none;

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__name {
      max-width: 166px;
      font-size: 14px;
      font-weight: 600;
      line-height: 148%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__timestamp {
      color: @grey-500;
      font-size: 10px;
      font-weight: 600;
      line-height: 100%;
    }

    &__message {
      color: @grey-600;
      font-size: 12px;
      line-height: 100%;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';