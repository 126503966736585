@import 'assets/less/variables';

.detail {
  margin-top: 16px;
  display: flex;
  align-items: center;

  svg {
    min-width: 24px;
    margin-right: 12px;

    path {
      fill: @gray-1;
    }
  }

  p {
    display: inline-block;
    font-weight: 600 !important;
    font-size: 14px;
    line-height: 24px;
    color: @gray-4;
    margin: 0;
  }

  &__vertical {
    p {
      display: block;

      &:first-child {
        font-size: 12px;
        line-height: 20px;
        color: @gray-1;
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';