@import 'assets/less/variables';

.talent-modal-form {
  &__content {
    margin-top: -8px;
    border-radius: 16px;

    .ant-form-item {
      margin-bottom: 20px;
    }
  }

  &__btns-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__controls {
    display: flex;
  }

  &__cancel-btn {
    font-weight: 600;
    font-weight: 600;
    line-height: 148%;
    color: @gray-4;
    border: 1px solid @gray-3;
    width: 114px;
  }

  &__save-btn {
    width: 114px;
    margin-right: 8px;
  }

  &__popover-wrapper .ant-popover-inner {
    border-radius: @border-radius-2;
  }

  &__delete-popover {
    width: 279px;
    padding: 16px;
  }

  &__popover-main-title {
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 24px;
    color: @dark;
  }

  &__popover-title {
    font-size: 14px;
    line-height: 24px;
    color: @gray-1;
    margin-bottom: 8px;
  }

  &__delete-controls-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__popover-btn {
    width: 84px;
    margin-left: 8px;
    font-weight: 500;
    color: @gray-1;
  }

  #delete-btn {
    background: @red;
    border-color: @red;
  }
}

.red-btn {
  background: @red !important;
  border-color: @red !important;
  color: #fff;
}

.red-btn:hover {
  border-color: @peach-red !important;
  background: @peach-red !important;
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';