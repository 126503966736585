@import 'assets/less/variables';

.speed-up-block {
  display: flex;
  justify-content: space-between;
  max-width: 1022px;
  margin-bottom: 136px;

  &__left {
    max-width: 352px;
    margin-right: 10px;

    .screen-md({
      margin: 0 auto;
    });
  }

  &__image {
    width: 100%;
  }

  &__right {
    max-width: 460px;
    margin-top: 115px;

    .screen-md({
      margin-top: 10px;
      max-width: none;
      text-align: center;
    });
  }

  &__title {
    font-family: @font-family-secondary;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 8px;

    .screen-sm({
      font-size: 20px;
      font-weight: 600;
    });
  }

  &__text {
    margin-bottom: 16px;
    color: @gray-4;

    .screen-sm({
      font-size: 14px;
    });
  }

  &__button {
    min-width: 190px;
    margin-right: 8px;

    .screen-sm({
      min-width: 134px;
    });
  }

  .screen-md({
    flex-direction: column;
    margin-bottom: 88px;
  });
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';