@import 'assets/less/variables';

.refer-your-colleagues {
  border-radius: @border-radius-5;
  position: relative;
  height: 328px;
  display: flex !important;
  justify-content: space-between;
  align-items: flex-end;
  background-image: url('~assets/images/make-your-profile-stand-out-bg.svg');
  padding: 46px 48px 54px 48px;
  overflow: hidden;

  .screen-lg({
    height: 446px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('~assets/images/make-your-profile-stand-out-bg-mobile.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 16px;
  });

  &__flexed {
    z-index: 1;

    .screen-lg({
      width: 275px;
  });
  }

  h1 {
    width: 461px;

    .screen-lg({
      width: unset;
      line-height: 32px !important;
      margin-bottom: 24px;
    });
  }

  p {
    width: 463px;
    color: #ffffff;
    margin-bottom: 24px;

    .screen-lg({
      width: unset;
      text-align: center;
    });
  }

  img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 283px;
    height: auto;

    .screen-lg({
      display: none !important;
    });
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';