@import 'assets/less/variables';

.refer-talent-link {
  span {
    display: block;
    font-weight: 400;
    line-height: 24px;
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 4px;

    .screen-lg({
        margin: 0 auto;
    });
  }

  &__link {
    position: relative;
    border-radius: @border-radius-3;
    width: 540px;
    height: 40px;
    background: #ffffff;
    padding: 8px 52px 8px 16px;

    .screen-lg({
        width: 100%;
        margin: 0 auto 24px auto;
    });

    div {
      font-weight: 400;
      line-height: 24px;
      font-size: 14px;
      color: @gray-1;

      white-space: nowrap;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  .ant-btn {
    position: absolute;
    top: 10px;
    right: 16px;
    width: 20px;
    height: 20px;
    border: none;
    background: none;
    padding: 0;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: @primary;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';