@import 'assets/less/variables';
@import 'assets/less/colors';

.page-card {
  display: flex;
  flex-flow: column;
  gap: 16px;
  min-height: unset;
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 14px;
  border-radius: @border-radius;
  border: 1px solid @grey-300;
  background: @light;

  &__content {
    max-width: unset;
    width: 100%;
    margin: 0 auto;
  }

  &__title {
    color: @grey-800;
    font-family: @font-family-primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 148%;
    text-transform: uppercase;
    margin-bottom: 0px;

    .screen-sm({
      font-size: 12px;
      line-height: 124%;
    });
  }

  &__children {
    position: relative;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';