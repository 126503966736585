@import 'assets/less/variables';
@import 'assets/less/colors';

.landing {
  &__background {
    position: absolute;
    top: 22px;
    right: 0;
    width: 479px;
    height: 696px;
    z-index: -1;

    .screen-lg({
        width: 242px;
        height: 351px;
      });
  }

  &__wrapper {
    margin: 0 auto;
    max-width: 1134px;
    padding: 123px 32px 0 32px;

    .screen-lg({
      padding-top: 32px;
    });
  }

  &__header {
    .screen-lg({
      margin-bottom: 32px;
    });

    h1 {
      margin: 24px 0 24px 0;
    }
  }

  &__additional-info {
    margin-top: 80px;
    display: flex;
    flex-direction: row !important;
    align-items: flex-start !important;
    gap: 16px;

    .screen-lg({
      margin-top: 40px;
    });

    &__icon {
      flex: 1 0 auto;
      width: 56px;
      height: 56px;
    }

    &__description {
      display: flex;
      flex-flow: column;
      align-items: flex-start !important;

      &__title {
        color: @grey-900;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
      }

      &__sub-title {
        color: @grey-700;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: unset !important;
        margin-bottom: 0 !important;
      }
    }
  }

  &__primary-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 162px;
    height: 48px;

    svg {
      margin-left: 16px;
      width: 24px;
      height: 24px;
    }
  }

  &__primary-button-link {
    display: flex;
    align-items: center;
  }

  &__secondary-button {
    width: 162px;
    height: 48px;
  }

  h2 {
    font-family: @font-family-secondary;
    font-weight: 700;
    font-size: 28px;
    line-height: 44px;
    color: @grey-900;

    .screen-lg({
      font-size: 20px;
      line-height: 28px;
    });
  }

  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: @grey-900;
    margin-bottom: 0;

    .screen-lg({
      font-size: 16px;
    });
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: @grey-600;

    .screen-lg({
      font-size: 14px;
      line-height: 22px;
    });
  }

  &__featured-facilities {
    margin-bottom: 64px;

    .screen-lg({
      margin-bottom: 72px;
    });
  }

  &__faqs {
    margin-bottom: 100px;

    .screen-lg({
      margin-bottom: 72px;
    });
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';