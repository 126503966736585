@import 'assets/less/variables';

.easy-block {
  display: flex;
  justify-content: space-between;
  max-width: 1022px;
  margin-bottom: 120px;

  &__title {
    margin-bottom: 2px;
    font-family: @font-family-secondary;
    font-size: 28px;
    font-weight: 700;

    .screen-md({
      font-size: 24px;
      text-align: center;
    });
  }

  &__sub-title {
    margin-bottom: 36px;
    font-size: 14px;
    color: @gray-4;

    .screen-md({
      margin-bottom: 32px;
      text-align: center;
    });
  }

  &__left {
    max-width: 422px;
    margin-right: 60px;

    .screen-md({
      max-width: none;
      margin: 0;
    });
  }

  &__items {
    .screen-md({
      margin-bottom: 24px;
    });
  }

  &__item {
    position: relative;
    display: flex;

    &:not(:last-child) {
      padding-bottom: 50px;
    }

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      top: 0;
      left: 16px;
      z-index: 0;
      width: 1px;
      height: 100%;
      background-image: linear-gradient(
        @primary 60%,
        rgba(255, 255, 255, 0) 0%
      );
      background-repeat: repeat-y;
      background-position: right;
      background-size: 2px 12px;
    }
  }

  &__icon {
    position: relative;
    z-index: 1;
    flex-shrink: 0;
    margin-right: 28px;
  }

  &__item-title {
    margin-bottom: 2px;
    font-size: 18px;
    font-weight: 600;
  }

  &__item-text {
    color: @gray-4;
  }

  &__right {
    max-width: 460px;

    .screen-md({max-width: none});
  }

  &__img {
    width: 100%;
  }

  .screen-md({
    flex-direction: column;
    margin-bottom: 88px;
  });
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';