@import 'assets/less/variables';

.image-carousel-modal {
  .ant-modal-content {
    .screen-lg({
      @media (orientation: portrait) {
        width: 96.2vh;
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
      }
    });
  }

  .ant-modal-body {
    padding: 0;
  }

  &__image-wrapper {
    margin: 0;
    height: 750px;
    padding-top: 24px;

    .screen-lg({
      padding-top: unset;
      max-width: 100%;
      max-height: 342px;
      display: flex !important;
      justify-content: center;
      align-items: center;
    });

    img {
      max-height: 628px;
      max-width: 1010px;

      .screen-lg({
        height: unset;
        max-width: 100%;
        max-height: 342px;
      });
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';