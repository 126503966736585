@import 'assets/less/variables';

.review-card {
  padding-top: 60px;

  .screen-lg({
    padding: 40px 0 36px 0;
  });

  &__card {
    border-radius: @border-radius-2;
    background: #fff;
    width: 352px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px 24px 24px;
    box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.04);

    .screen-lg({
      width: 100%;
      height: unset;
      border: 1px solid @gray-7;
      box-shadow: none;
    });

    img {
      margin: -48px auto 22px auto;
      width: 96px;
      height: 96px;

      .screen-lg({
        margin: -40px auto 4px auto;
        width: 80px;
        height: 80px;
      });
    }

    div {
      text-align: center;
      font-size: 20px;
      line-height: 32px;
      color: @gray-4;
      margin-bottom: 16px;

      .screen-lg({
        font-size: 14px;
        line-height: 24px;
      });
    }
  }

  h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: @gray-4;
    margin-bottom: 4px;
  }

  span {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #7484a2;
    margin-bottom: 16px;

    .screen-lg({
      margin-bottom: 8px;
    });
  }

  &__review {
    width: 34px;
    min-height: 41px;
    margin-bottom: 16px;

    .screen-lg({
      margin-bottom: 8px;
    });
  }

  &__review-rating {
    width: 132px;
    height: 20px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';