@import 'assets/less/variables';

.professional-exam-form {
  &__exam-id-form-item {
    display: none;
  }

  &__exam-status-form-item {
    margin-bottom: 10px;

    &__radio {
      display: flex;
      flex-flow: column;
      gap: 10px;

      span:not(.ant-radio) {
        line-height: 148%;
      }
    }
    .ant-form-item-row {
      flex-flow: column;
    }
  }

  &__date-range-picker {
    width: 100%;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';