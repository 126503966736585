@import 'assets/less/variables';

.talent-matches {
  width: 100%;

  &__view-btn {
    font-size: 16px;
    font-weight: 600;
    line-height: 148%;
  }

  .ant-table {
    font-size: 14px;
    color: @dark-2;
  }

  .ant-table-thead {
    .ant-table-cell {
      padding: 10px 16px;
      background: #fff;
      text-transform: uppercase;
      border-bottom: 1px solid @gray-2;
      color: @gray-8;
      font-weight: bold;
    }
  }

  .ant-table-row {
    .ant-table-cell {
      padding: 24px 16px;
    }
    &:nth-child(even) {
      background: @primary-light;
      border-bottom: 1px solid @gray-2;
    }
  }

  &__status-name {
    display: flex;
    align-items: center;
    gap: 4px;

    &__popover {
      cursor: pointer;
      width: 20px;
      height: 20px;

      &:hover {
        path {
          fill: @primary;
        }
      }

      &__content {
        color: @dark;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 156%;
        max-width: 370px;
      }
    }
  }

}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';