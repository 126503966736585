@import 'assets/less/variables';

.role {
  position: relative;
  padding-top: 123px;

  .screen-lg({
    padding-top: 32px;
  });

  &__page-header-margin {
    margin-bottom: 150px;

    .screen-lg({
      margin-bottom: 72px;
    });
  }

  &__wrapper {
    margin: 0 auto;
    max-width: 1134px;
    padding: 0 32px;
  }

  &__primary-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 162px;
    height: 48px;

    svg {
      margin-left: 16px;
      width: 24px;
      height: 24px;
    }
  }

  &__secondary-button {
    height: 48px;
  }

  h2 {
    text-align: center;
    font-family: @font-family-secondary;
    font-weight: 700;
    font-size: 28px;
    line-height: 44px;
    color: @dark;

    .screen-lg({
      font-size: 20px;
      line-height: 28px;
    });
  }

  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: @dark;
    margin-bottom: 0;

    .screen-lg({
      font-size: 16px;
    });
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: @gray-1;

    .screen-lg({
      font-size: 14px;
      line-height: 22px;
    });
  }

  &__featured-vacancies {
    margin: 44px 0 72px 0;

    p {
      margin: 0 auto 44px auto;
      width: 772px;
      text-align: center;

      .screen-lg({
        width: 100%;
      });
    }

    h4 {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: @dark;
      margin-bottom: 0;

      .screen-lg({
        text-align: center;
      });
    }

    &-content {
      display: flex;
      justify-content: space-between;

      .screen-lg({
        flex-direction: column-reverse;
        justify-content: flex-start
      });
    }

    &-vacancies {
      width: calc(100% - (288px + 48px));

      .screen-lg({
        width: 100%;
      });
    }

    &-vacancy {
      border-radius: @border-radius-2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      .screen-lg({
        flex-direction: column;
      });

      &:hover {
        background-color: #f6fbff;
      }

      div:first-child {
        span {
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          color: @gray-1;
          margin-right: 16px;

          .screen-lg({
            display: block;
            margin-right: unset;
            text-align: center;
          });
        }
        span:last-child {
          margin-right: unset;

          .screen-lg({
            text-align: center;
            display: block;
          });
        }
      }

      a {
        display: block;
        border-radius: @border-radius-3;
        padding: 12px;
        width: 143px;
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

        &:hover {
          color: #fff;
          background: @primary;
        }
      }
    }
  }

  &__roles {
    border-radius: @border-radius-2;
    height: min-content;
    width: 288px;
    background-color: #fafafa;
    padding: 24px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: @bgc-primary;
      border-top-right-radius: @border-radius-2;
      border-bottom-right-radius: @border-radius-2;
    }
    &::-webkit-scrollbar-thumb {
      background: @gray-2;
      border-radius: @border-radius-3;
    }

    a {
      margin-top: 16px;
      display: block;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: @gray-3;
    }

    .ant-checkbox-wrapper {
      margin-right: 8px;
    }
  }

  &__salary-estimation-sign-up {
    margin-bottom: 80px;

    .screen-lg({
      margin-bottom: 72px;
    });
  }

  &__faqs {
    margin-bottom: 100px;

    .screen-lg({
      margin-bottom: 72px;
    });
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';