@import 'assets/less/variables';

.general-information {
  margin: 24px 24px 0 24px;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #f4f5f7;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  color: @gray-4;

  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: @dark;
  }

  &__status {
    width: fit-content;
    height: 26px;
    padding: 1px 8px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: @gray-1;
    background: @gray-6;
    border-radius: 4px;
  }

  &__facility-name {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: @gray-1;
  }

  &__info-wrapper {
    display: flex;
    flex-direction: column;

    .ant-tag {
      border: none;
      padding: 0;
      margin-top: 20px;
      background: #fff;
      font-weight: 600;
      font-size: 14px;
      white-space: initial;
      display: inline-flex;
      align-items: center;
      color: @gray-4;
      svg {
        margin-right: 5px;
        min-width: 24px;
      }
    }
  }

  &__record {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: @gray-4;
    background: @light;
    border: 1px solid @gray-3;
    border-radius: 6px;
  }

  &__record:last-child {
    margin-bottom: 0;
  }

  &__form {
    width: 100%;
    max-width: 520px;
    &-actions {
      .ant-btn {
        width: 170px;
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';