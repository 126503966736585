@import 'assets/less/variables';

.hp-user-candidates {
  width: 100%;

  &__wrapper {
    padding: 16px;
    margin: 24px;
    min-height: calc(100% - 24px);
    background: @light;
    border: 1px solid #f4f5f7;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: @border-radius-5;
  }

  &__stab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__header-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: @dark;
  }

  &__status-selector {
    width: 350px;
    margin-left: 12px;
  }

  &__status-selector-title {
    font-weight: 600;
    font-size: 14px;
    color: @gray-4;
  }

  &__status-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: @dark;
  }

  &__cards-wrapper {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fill, 348px);
    margin: 24px 0;
  }

  &__title {
    margin-top: 12px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: @dark;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: @gray-1;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';