@import 'assets/less/variables';

.hero-banner {
  position: relative;
  display: flex;
  justify-content: center;
  opacity: 0.9;
  background: linear-gradient(180deg, #0093fd 0%, #3576C0 100%);

  .screen-lg({
    margin-bottom: 24px;
    flex-direction: column;
  });

  &__wrapper {
    width: 860px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .screen-lg({
      width: unset;
      padding: 0px 10px;
    });
  }

  &__title {
    margin: 140px 0 16px;
    color: @light;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -1.92px;
    text-align: center;

    .screen-lg({
      font-size: 40px;
      line-height: normal;
      letter-spacing: -1px;
      text-transform: capitalize;
    });
  }

  &__description {
    color: @light;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 148%;
    text-align: center;
    margin-bottom: 48px;
  }

  &__action-container {
    display: flex;
    margin-bottom: 110px;

    .screen-lg({
      flex-direction: column;
      align-items: center;
    });
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';