@import 'assets/less/variables';

.faqs-card {
  h2 {
    text-align: center;
  }

  p {
    width: 760px;
    text-align: center;
    margin-bottom: 32px;

    .screen-lg({
      width: unset;
    });
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-collapse {
    background: none;
  }

  .ant-collapse > .ant-collapse-item {
    border: none;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: @dark;

    .screen-lg({
      font-size: 18px;
      line-height: 26px;
    });
  }

  .ant-collapse-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: @gray-1;

    .screen-lg({
      font-size: 14px;
      line-height: 22px;
    });
  }

  .ant-collapse-header-text {
    user-select: none;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 16px 16px 16px;
  }

  .ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding-top: unset;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';