@import 'assets/less/variables';
@import 'assets/less/colors';

.inbox-messages-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 72px);

  &__wrapper {
    max-height: 100%;
    overflow: auto;
    padding: 0 24px;

    .screen-lg({
      padding: 0 16px;
    });
  }

  &__divider {
    margin: 0 !important;
    padding: 16px 0;

    .ant-divider-inner-text {
      padding: unset;
    }

    &__inner-text {
      padding: 4px 12px;
      border-radius: 100px;
      border: 1px solid @grey-200;
      background: @white;
      color: @grey-700;
      font-size: 12px;
      line-height: 20px;
    }
  }

  &__message {
    max-width: 50%;
    clear: both;
    padding: 8px 12px;
    border-radius: 12px;
    border: 1px solid @grey-100;

    & + & {
      margin-top: 12px;
    }

    &:last-child {
      margin-bottom: 12px;
    }

    &--inbound {
      float: left;
      background-color: @white;
    }

    &--outbound {
      float: right;
      background-color: @blue-200;
    }

    &__status {
      color: @grey-500;
      font-size: 10px;
      line-height: normal;
      letter-spacing: -.1px;
      margin-top: 4px;
      display: flex;
      flex-flow: row;
      align-items: center;
      gap: 4px;
      float: right;

      > svg path {
        fill: currentColor;
      }
    }
  }

  &__message-form {
    position: relative;
    margin: 0 10px 10px;

    .screen-lg({
      margin: 0;
    });

    &__inactive-card {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: center;
      width: 100%;
      height: 90px;
      padding: 8px;
      border-radius: 16px;
      border: 1px solid @grey-400;
      background-color: @grey-100;

      .screen-lg({
        height: 120px;
        border-radius: unset;
        border: initial;
        border-top: 1px solid @grey-400;
      });

      &__icon {
        min-width: 32px;
        min-height: 32px;
      }

      &__text {
        max-width: 382px;
        color: @grey-600;
        font-size: 14px;
        line-height: 24px;
      }
    }

    &__input {
      margin-bottom: 0px;
      padding: 10px 2px 44px 5px;
      border-radius: 16px;
      border: 1px solid @grey-300;
      background-color: @white;

      .screen-lg({
        border-radius: unset;
        border: initial;
        border-top: 1px solid @grey-300;
      });

      &__textarea {
        border: unset;
        box-shadow: unset !important;
        background-color: @white !important;
      }
    }

    &__button {
      position: absolute !important;
      bottom: 8px;
      right: 8px;
      height: 32px;
      width: 32px;
      padding: 8px;
      border-radius: 100px;

      &--disabled {
        color: @grey-500 !important;
        background-color: @white !important;
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';