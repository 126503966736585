@import 'assets/less/variables';

.share-link {
  &__subtitle {
    font-size: 14px;
    line-height: 24px;
    color: @gray-4;
    margin-bottom: 8px;
  }

  &__save-btn {
    width: 115px;
  }

  &__show-form-btn {
    width: 115px;
  }

  &__btns-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__popover-btn {
    margin-left: 8px;
    font-weight: 500;
    color: @gray-1;
  }

  &__deactivate-btn {
    top: 2px;
  }

  &__link-btn {
    font-weight: 600;
    font-size: 12px;
    color: @primary;
    margin-left: 16px;

    span {
      margin-left: -3px;
    }
  }

  &__column-text {
    height: 24px;
    font-size: 12px;
    line-height: 24px;
    color: @dark-2;
    word-break: break-word;
    white-space: pre-wrap;
  }

  &__column-title {
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: @gray-8;
  }

  &__odd-row {
    background-color: @primary-light;
  }

  &__pending-status {
    padding: 2px 12px;
    height: 24px;
    width: 72px;
    background: #ecf5ff;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;

    color: #3b9cfe;
  }

  &__requested-status {
    padding: 2px 12px;
    width: 141px;
    height: 24px;
    background: #dcffdf;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: @success;
  }

  &__tolltip-inner-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__declined-status {
    margin-right: 4px;
    padding: 2px 12px;
    width: 76px;
    height: 24px;
    background: #ffc7c7;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: center;

    color: #c5292a;
  }
}

.add-class {
  font-size: 14px;
  line-height: 24px;
  color: @dark-2;

  &-link {
    color: @primary;
  }
}

.admin-tool-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;

  &__title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: @dark;
  }

  &__add-btn {
    margin-left: 16px;
  }

  &__share-btn {
    width: 115px;
  }

  &__crumb {
    margin-bottom: 8px;
  }

  &__crumb-item {
    font-size: 14px;
    color: @gray-1;
  }
}

.search-input {
  background: #ffffff;
  border: 1px solid #dee1e7;
  border-radius: 10px;
  width: 344px;

  &__icon {
    height: 19px;
    width: 19px;
    margin-right: 8px;

    circle,
    line {
      stroke: @gray-4;
    }
  }
}

.dropdown {
  &__logo {
    .screen-lg({
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    });

    .screen-sm({
      width: 132px;
      height: 18px;
    });
  }

  &__nav {
    margin-left: 32px;
    display: flex;
  }

  &__menu {
    box-shadow: @box-shadow-2;
    border-radius: @border-radius-2;
  }

  &__menu-item {
    color: @gray-4;
    padding: 16px 24px;

    &:hover {
      background: @primary-light;
    }
  }

  &__nav-icon {
    margin-right: 8px;
  }

  &__user-link {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  &__user-link-icon {
    margin-left: 12px;
  }

  &__nav-item {
    display: flex;
    align-items: center;
    margin-right: 32px;
    color: @gray-4;
    transition: color 0.3s;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;

    &_bold {
      font-weight: 600;
    }

    &_blue {
      font-weight: 600;
      color: @primary;
    }

    &:hover {
      color: @primary;
    }

    &--active {
      font-weight: 600;
      color: @primary;
    }
  }

  &__nav-arrow {
    position: relative;
    top: -2px;
    margin-left: 4px;
  }

  &__user-info {
    display: flex;
    cursor: pointer;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';