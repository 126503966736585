@import 'assets/less/variables';

.why-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;

  .screen-lg({
    margin-bottom: 72px;
  });

  h2 {
    text-align: center;
    margin-bottom: 56px;

    .screen-lg({
      margin-bottom: 66px;
    });
  }

  h3 {
    text-align: center;
  }

  p {
    text-align: center;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .screen-lg({
      flex-direction: column;
      justify-content: unset;
      align-items: center;
    });

    div {
      width: 240px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .screen-lg({
        margin-bottom: 64px;
      });

      svg {
        width: 56px;
        height: 56px;
        margin-bottom: 16px;
      }
    }

    div:last-child {
      .screen-lg({
          margin-bottom: 0;
      });
    }

    p {
      margin-bottom: 0;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';