@import 'assets/less/colors';

.button {
  .common() {
    display: flex;
    gap: 8px;
    align-items: center;
    width: auto;
    height: unset;
    padding: 8px 32px;
    box-shadow: unset;
    font-weight: 600;
    text-shadow: unset;

    &:before {
      display: none !important;
    }

    &[disabled] {
      cursor: default;
    }

    > svg:first-child {
      width: 24px;
      height: 24px;

      path {
        fill: currentColor;
      }
    }

    .anticon-loading {
      height: 16px;
      width: 16px;
    }
  }

  &--primary {
    .common();
    border: none;

    &, &:focus {
      color: @white;
      background-color: @blue;
    }
    &:hover {
      background-color: @blue-700;
    }
    &:active {
      background-color: @blue-800;
    }
    &[disabled], &[disabled]:hover {
      color: @grey-600;
      background-color: @grey-300;
    }
  }

  &--secondary {
    .common();

    &, &:focus {
      color: @grey-700;
      border-color: @grey-500;
      background-color: transparent;
    }
    &:hover {
      color: @blue;
      border-color: @blue;
    }
    &:active {
      color: @blue-800;
      border-color: @blue-800;
    }
    &[disabled], &[disabled]:hover {
      color: @grey-400;
      border-color: @grey-500;
    }
  }

  &--tertiary {
    .common();
    border: none;

    &, &:focus {
      color: @blue;
      background-color: @blue-200;
    }
    &:hover {
      background-color: @blue-300;
    }
    &:active {
      color: @blue-800;
      background-color: @blue-500;
    }
    &[disabled], &[disabled]:hover {
      color: @grey-500;
      background-color: @grey-900-a-5;
    }
  }

  &--text {
    .common();
    padding: unset;

    &, &:focus {
      color: @grey-800;
      background-color: transparent !important;
    }
    &:hover {
      color: @grey-600;
    }
    &:active {
      color: @grey-700;
    }
    &[disabled], &[disabled]:hover {
      color: @grey-400;
    }
  }

  &--link {
    .common();
    padding: unset;

    &, &:focus {
      color: @blue;
    }
    &:hover {
      color: @blue-700;
    }
    &:active {
      color: @blue-800;
    }
    &[disabled], &[disabled]:hover {
      color: @grey-500;
    }
  }

  &--small {
    padding: 4px 32px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';