@import 'assets/less/colors';

.inbox-thread-vacancies {
  border: none;
  background-color: @grey-100;

  &__vacancy {
    border: 1px solid @grey-300;
    border-radius: 2px !important;
    background: @white;

    &:not(:first-child) {
      margin-top: 10px;
    }

    &__header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      user-select: none;

      &__name {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;

        span {
          color: @grey-900;
          font-size: 14px;
          font-weight: 600;
          line-height: 100%;
        }

        a {
          line-height: normal;
        }
      }

      &__status {
        cursor: pointer;
        display: flex;
        align-items: center;
        border: none;
        width: fit-content;
        font-weight: 600;
        line-height: 24px;
        text-transform: uppercase;
      }
    }

    &__expand-icon {
      height: 52px;
      display: flex !important;
      align-items: center;
      justify-content: center;

      &__expanded {
        transform: rotate(90deg);
      }
    }

    .ant-collapse-icon-position-end &__expand-icon {
      padding-left: 12px;
    }

    &__divider {
      margin: 16px 0px;
      stroke: @grey-300;
    }

    &__status {
      display: flex;
      gap: 8px;
      justify-content: center;
      padding-bottom: 12px;
    }

    &__timestamp {
      display: flex;
      justify-content: center;
      color: @grey-700;
      font-size: 12px;
      font-weight: 400;
      line-height: 124%;
      user-select: none;
    }

    .ant-collapse-header {
      padding: 16px !important;
      padding-right: 40px !important;
    }

    .ant-collapse-content-box {
      padding: 12px 8px;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';