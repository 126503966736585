@import 'assets/less/variables';

.edit-profile-job-preference {
  &__radio-btn {
    margin-right: 48px;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 16px;
    line-height: 24px;
  }

  &--sub-title {
    font-size: 14px;
    color: @dark;
  }

  &__info {
    display: flex;
    margin-bottom: 16px;
    padding: 16px;
    border-radius: @border-radius-2;
    background: @gray-6;
  }

  &__icon {
    flex-shrink: 0;
    margin-right: 16px;
  }

  &__text {
    font-size: 12px;
    color: @gray-4;
  }

  &__checkbox-text {
    font-size: 14px;
    color: @gray-4;
  }

  &__checkbox-link {
    font-weight: 600;
  }

  &__radio-group-options {
    .ant-radio-wrapper {
      gap: 8px;
      align-items: flex-start;
      margin-right: 32px;

      &:after {
        display: none;
      }

      span {
        &:first-child .ant-radio-inner {
          width: 24px;
          height: 24px;
        }

        &:last-child {
          color: @dark;
          font-size: 16px;
          font-weight: 400;
          line-height: 148%;
          padding: 4px 0 0;
        }
      }
    }
  }

  &__list-item-wrapper {
    display: flex;
    gap: 8px;

    .screen-sm({
      display: initial;
    });

    > :first-child {
      flex-grow: 3;
      flex-basis: 0;
    }

    > :last-child {
      flex-grow: 2;
      flex-basis: 0;
    }
  }

  &__search-areas-form-list {
    margin-bottom: 8px;

    &__add-button {
      margin-bottom: 0;
      padding-top: 0;
      height: unset;
    }
  }
}


@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';