@import 'assets/less/variables';

.beneficial-block {
  max-width: 1022px;
  margin-bottom: 115px;

  &__sub-title {
    margin-bottom: 48px;
  }

  &__item {
    text-align: center;
  }

  &__icon {
    margin-bottom: 10px;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
  }

  &__text {
    max-width: 235px;
    margin: 0 auto;
    font-size: 14px;
    color: @gray-4;
  }

  .screen-md({
    margin-bottom: 92px;
  });
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';