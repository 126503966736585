@import 'assets/less/variables';

.modal-sm {
  .ant-modal-header {
    padding: 16px 60px 0 24px;
    border-bottom: none;
  }

  .ant-modal-close {
    svg {
      path {
        fill: @gray-2;
      }
    }
  }

  .ant-modal-body:not(:last-child) {
    padding-bottom: 0px;
  }

  .ant-modal-close {
    top: 12px;
    right: 4px;
  }

  .ant-modal-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    border-top: unset;
    text-align: unset;
    padding: 16px 24px;

    .ant-btn-default {
      border: none;
      color: @gray-3;
    }
  }

  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin-left: unset;
  }
}

.modal-md {
  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-header {
    border-radius: 16px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';