@import 'assets/less/variables';
@import 'assets/less/colors';

.referrals {
  min-height: 100%;
  background: @bgc-primary;
  padding-bottom: 16px;

  &__wrapper {
    .screen-lg({
      padding: 0 16px;
    });
  }

  &__header {
    margin-bottom: 26px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1138px;
    margin: 0 auto;
    padding: 40px;
    border-radius: 16px;
    border: 1px solid @grey-300;
    background: @light;

    .screen-lg({
      padding: 24px;
    });
  }

  &__title {
    color: @dark;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;

    .screen-lg({
      text-align: center;
      line-height: 29px;
      margin-bottom: 4px;
    });
  }

  &__subtitle {
    max-width: 528px;
    color: @gray-1 !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 40px;

    .screen-lg({
      margin-bottom: 24px;
    });
  }

  &__steps {
    display: flex;
    flex-flow: row;
    margin-bottom: 40px;

    .screen-lg({
      display: none;
    });
  }

  &__step {
    width: 220px;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }

    &__title {
      color: @dark;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
    }

    &__description {
      color: @gray-1;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
    }

    .screen-lg({
      display: block;
      margin: 0 auto 24px;
    });

    svg {
      display: block;
      margin: 0 auto 16px;
      width: 48px;
      height: 48px;
    }
  }

  &__referral-statistics {
    margin-bottom: 40px;

    div {
      background: @primary-light !important;
    }
  }

  &__refer-talent-link {
    margin-bottom: 40px;

    .screen-lg({
      max-width: 100%;
      margin-bottom: 24px;
    });

    span {
      color: @primary !important;
    }

    .refer-talent-link__link {
      background: @primary-light !important;
    }
  }

  &__socials {
    &__link {
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }

      .screen-lg({
        margin-right: 12px;
      });
    }

    span {
      display: block;
      text-align: center;
      font-weight: 400;
      line-height: 24px;
      font-size: 14px;
      color: @primary;
      margin-bottom: 16px;

      .screen-lg({
        margin-bottom: 0;
      });
    }

    svg {
      width: 54px;
      height: 54px;
    }
  }

  &__policy {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 4px;
    margin: 0 auto;
    margin-top: 16px;

    svg path {
      fill: @primary;
    }

    &__text {
      font-size: 14px;
      font-weight: 600;
      text-decoration: underline;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';