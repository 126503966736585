@import 'assets/less/variables';

.blog-post-card {
  &__wrapper {
    position: relative;
    width: 346px;

    .screen-lg({
      width: 100%;
    });
  }

  &__tag {
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 100px;
    background-color: @primary;
    padding: 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 16px;
    font-weight: 600;
    font-size: 12px;
    color: #ffffff;
  }

  img {
    width: 346px;
    height: 236px;
    border-radius: @border-radius-5;
    margin-bottom: 16px;

    .screen-lg({
      width: 100%;
      height: unset;
    });
  }

  p {
    color: #8e9298;
    margin-bottom: 4px;
  }

  h3 {
    color: @gray-4;
    margin-bottom: 0;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';