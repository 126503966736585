@import 'assets/less/variables';

.candidate {
  &__custom-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    h1 {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      color: @dark;
      margin-bottom: 0;
    }

    &-buttons {
      display: flex;
      margin-right: 38px;
    }

    .ant-btn-text,
    .ant-btn-link {
      height: 24px;
      display: flex;
      align-items: center;
      padding: 0 16px !important;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: @gray-2;

      svg {
        width: 19px;
        height: auto;
        margin-right: 16px;

        path {
          fill: @gray-2;
        }
      }
    }
  }

  &__close-icon {
    margin: 18px 18px 0 0;
    width: 20px;
    height: 20px;
  }

  &__head-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: @dark;
    margin-bottom: 4px;
  }

  &__profile-picture {
    margin-bottom: 24px;
  }

  &__sub-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: @gray-1;

    &:not(:first-of-type):not(:empty) {
      padding-top: 12px;
    }
  }

  &__match-count {
    margin-left: 12px;
    border-radius: @border-radius-2;
    background-color: @light-blue;
    padding: 4px 8px;
    line-height: 20px;
    font-weight: 600;
    font-size: 14px;
    color: @primary;
  }

  &__success {
    background-color: @light-green;
    color: @success;
  }

  &__status-tag {
    height: 33px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 4px 10px;
    font-weight: 600;
    border: none;

    svg {
      margin-left: 4px;
    }
  }

  &__velocity {
    margin-top: 24px;
    width: 100%;
    display: flex;

    &-wrapper {
      border: unset;
      padding: unset;

      flex-grow: 1;
      display: flex;
      height: 62px;
      overflow: hidden;

      &:first-child {
        border-radius: 8px 0px 0px 8px;
        margin: 0;
      }
      &:last-child {
        border-radius: 0px 8px 8px 0px;
      }

      &-primary {
        background-color: @primary !important;

        .candidate__velocity-content {
          background: @primary;
          color: #fff;

          svg {
            width: 15px;
            height: auto;

            path {
              fill: #fff;
            }
          }
        }

        .candidate__velocity-triangle-left {
          background: @primary;
        }
        .candidate__velocity-triangle-right {
          border-left: 12px solid @primary;
        }
      }

      &-grey {
        background: #f9f9fb !important;

        .candidate__velocity-content {
          background: #f9f9fb;
          color: @gray-2;

          svg {
            width: 20px;
            height: auto;

            path {
              fill: @gray-2;
            }
          }
        }

        .candidate__velocity-triangle-left {
          background: #f9f9fb;
        }
        .candidate__velocity-triangle-right {
          border-left: 12px solid #f9f9fb;
        }
      }

      &-light {
        background: @light-blue !important;

        .candidate__velocity-content {
          background: @light-blue;
          color: @primary;

          svg {
            width: 14px;
            height: auto;

            path {
              fill: @primary;
            }
          }
        }

        .candidate__velocity-triangle-left {
          background: @light-blue;
        }
        .candidate__velocity-triangle-right {
          border-left: 12px solid @light-blue;
        }
      }
    }

    &-triangle-left {
      border-top: 31px solid transparent;
      border-bottom: 31px solid transparent;
      border-left: 12px solid #fff;
    }

    &-content {
      width: calc(100% - 12px);
      height: 62px;
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        margin-left: 16px;
      }

      span {
        display: block;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        &:first-child {
          font-weight: 600;
        }
      }

      &:last-child,
      &:first-child {
        width: calc(100% - 12px);
      }
    }

    &-triangle-right {
      background-color: #fff;
      border-top: 31px solid transparent;
      border-bottom: 31px solid transparent;
      border-left: 12px solid @primary;
    }
  }

  &__margin-right {
    margin-right: 8px;
  }

  &__margin-top {
    margin-top: 3px;
  }

  &__scrollable {
    height: 40vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__decline-reason {
    width: calc(100% - 24px);
    padding: 8px 12px 8px 16px;
    background: @gray-6;
    border-radius: 8px;
    margin-bottom: 24px;

    span {
      display: block;

      &:first-child {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: @gray-1;
      }

      &:last-child {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: @gray-4;
      }
    }
  }

  &__record {
    display: inline-flex;
    flex-direction: row;
    margin: 0 8px 8px 0;
    padding: 4px 8px;
    border: 1px solid #a7aeba;
    border-radius: 6px;

    svg {
      margin-right: 8px;

      path:not([fill="white"]) {
        fill: @gray-4;
      }
    }

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: @gray-4;
    }
  }

  h2 {
    margin: 24px 0 4px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: @dark;
  }

  &__contact-wrapper {
    margin-top: 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
    color: @gray-4;

    svg {
      margin-right: 12px;
    }
  }

  &__motivation {
    display: inline-block;
    margin: 0 10px 8px 0;

    svg {
      margin: 3px 8px 0 0;
      min-width: 13px;

      path {
        fill: @primary;
      }
    }

    span {
      line-height: 24px;
      font-size: 14px;
      line-height: 24px;
      color: @primary;

      &:last-child {
        padding-left: 6px;
        font-weight: 700;
        word-break: break-word;
        white-space: break-spaces;
      }
    }

    &-content {
      display: flex;
      padding: 4px 8px;
      background: @primary-light-4;
      border-radius: @border-radius-3;
      white-space: nowrap;

      .screen-sm({
        display: flex;
        flex-direction: row;
        width: 100%;
        white-space: unset;
      });
    }

    &-inner {
      display: inline-block;
      padding-right: 8px;
      word-break: break-word;
    }
  }

  &__sub-header {
    font-size: 12px;
    line-height: 20px;
    color: @gray-1;
  }

  &__priority {
    display: flex;
    white-space: unset;
    word-break: break-word;
    padding: 4px 8px 4px 8px;

    background: @gray-6;
    border-radius: @border-radius-3;
    margin-bottom: 8px;

    svg {
      margin: 0 8px -8px 0;
      width: 16px;
    }

    span {
      font-size: 14px;
      line-height: 24px;
      color: @gray-1;
      &:last-child {
        word-break: break-word;
        white-space: break-spaces;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.decline-modal__sub-title {
  margin: -12px 0 12px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6b778c;
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';