@import 'assets/less/variables';
@import 'assets/less/colors';

.add-general-information {
  margin: 16px 24px;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #f4f5f7;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  height: calc(100% - 150px);
  color: @gray-4;

  &__form {
    width: 100%;
    max-width: 520px;
    &-actions {
      .ant-btn {
        width: 170px;
      }
    }

    .ant-input-number-group-addon {
      padding: 0;
    }
  }

  &__icon-wrapper {
    width: 22px;
    svg {
      width: 100%;
    }
  }

  &__assignee-option {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    p {
      margin: 0;
    }
  }

  &__assignee-vacancies {
    color: @gray-1;
  }

  &__delete-icon {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    border: none;
    svg {
      width: 22px;
    }
  }

  &__row-item {
    width: 342px;

    .ant-input-number {
      width: 100% !important;
    }

    .ant-input-number-input {
      text-align: center;
    }
  }

  &__experience_button {
    background: #fff;
    padding: 0;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      path {
        fill: #091828;
      }
    }
  }

  &__facility-dropdown {
    .ant-select-item-option {
      padding: 8px 16px;

      &-active {
        background-color: @blue-100;

        span {
          &:last-child {
            color: @primary;
          }
        }
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';