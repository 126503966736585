@import 'assets/less/variables';

.hp-user-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 112px;
  padding: 24px;
  background: @light;
  box-shadow: @box-shadow-3;

  h1 {
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
    color: @dark;
    margin-bottom: 0;
  }

  h2 {
    margin-top: 4px;
    font-size: 16px;
    color: @gray-3;
    margin-bottom: 0;
  }

  &__crumb {
    margin-bottom: 8px;
  }

  &__crumb-item {
    font-size: 14px;
    color: @gray-1;
    font-weight: normal;
    padding: 0;
    height: unset;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__user {
    width: auto;
    background: #ffffff;
    border: 1px solid #dee1e7;
    border-radius: 10px;
    border: none;
    .ant-input {
      width: 60px;
      &:focus {
        width: 250px;
      }
    }
  }

  &__icon {
    height: 19px;
    width: 19px;
    margin-right: 8px;

    circle,
    line {
      stroke: @gray-4;
    }
  }

  &__divider {
    margin: 0 16px;
    height: 24px;
    background: @gray-3;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';