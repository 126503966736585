@import 'assets/less/variables';

.admin-sidebar {
  background-color: @bgc-primary;
  height: 100%;

  &__logo-wrapper {
    margin: 10px 0 10px 20px;
  }

  &__logo {
    width: 191px;
    height: 32px;
    margin-right: 16px;
  }

  &__nav-item {
    margin: 0;

    &.ant-menu-submenu-active,
    &.ant-menu-item-active {
      path {
        fill: @primary;
      }
    }

    &.ant-menu-submenu-selected,
    &.ant-menu-item-selected {
      background-color: @gray-6 !important;

      &::after {
        border: none;
      }

      path {
        fill: @primary;
      }
    }

    &.ant-menu-submenu-selected svg:first-of-type,
    &.ant-menu-item-selected svg:first-of-type {
      display: none;
    }

    &:not(.ant-menu-submenu-selected, .ant-menu-item-selected)
      svg:last-of-type {
      display: none;
    }

    &.ant-menu-submenu svg:only-of-type,
    &.ant-menu-item svg:only-of-type {
      display: block;
    }

    &.ant-menu-submenu-selected .ant-menu-submenu-title,
    &.ant-menu-item-selected .ant-menu-title-content {
      font-weight: bold;
    }

    path {
      fill: @gray-4;
      transition: 0.5s;
    }

    span {
      font-size: 14px;
    }

    svg {
      width: 16px;
      height: 20px;
    }
  }

  &__nav-logout {
    position: absolute !important;
    bottom: 0px;
    height: auto !important;
    margin: 16px 0 24px 0 !important;
    padding: 0 12px 0 24px !important;
    background-color: @bgc-primary;
  }

  .ant-menu-root {
    max-height: calc(100% - 136px);
    overflow-y: auto;
    overflow-x: hidden;
    background: @bgc-primary;
    border-right: none;
  }

  .ant-menu-submenu-arrow {
    color: inherit !important;
  }
}

.ant-menu-item::after {
  border: unset !important;
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';