@import 'assets/less/variables';
@import 'assets/less/colors';

.radio-group {
  display: flex;

  &__item {
    &:not(:last-child) {
      margin: 0 10px 0 0;
    }

    &:last-child > &__after-btn {
      margin-bottom: 0px;
    }
  }

  &__icon {
    flex-shrink: 0;
    margin-right: 10px;
    width: 32px;
    height: 32px;

    .screen-sm({
      margin-right: 8px;
      width: 24px;
      height: 24px;
    });

    &--selected {
      padding-top: 6px;
    }

    &--margin-left {
      margin-left: -4px;
      margin-right: 0;
      margin-bottom: 1px;
    }

    path {
      fill: @gray-4;
      color: @gray-4;
      transition: fill 0.3s;
    }
  }

  & :nth-of-type(4) &__icon {
    margin-bottom: -5px;
  }

  &__btn {
    display: flex;
    align-items: center;
    height: auto;
    min-height: 40px;
    padding: 14px 16px;
    line-height: 1.2;
    color: @gray-3;
    border: 1px solid @grey-300;
    user-select: none;

    .screen-sm({
      padding: 12px 16px;
    });

    &--simplified {
      border: none !important;
      background-color: unset !important;
      padding: 0 0 0 21px !important;
      z-index: 1;

      &.ant-radio-button-wrapper-checked .ant-radio-button-input {
        outline: 10px auto @primary;
        outline-offset: -5px;
      }

      .ant-radio-button {
        top: 11px;
      }

      .radio-group__icon {
        display: none !important;
      }

      input[type='radio'] {
        width: 14px;
        height: 14px;
        opacity: inherit;
      }

      .radio-group__name {
        color: @dark;
        font-size: 16px;
        font-weight: 400;
        line-height: 148%;
      }
      .radio-group__after-btn {
        margin-top: 0;
      }
    }

    &.ant-radio-button-wrapper > span:nth-child(2) {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &.ant-radio-button-wrapper-checked {
      background-color: @blue-100;
      border-color: #bfdfff !important;
      box-shadow: 0 0 15px #f6fbff !important;

      .radio-group__name {
        color: @primary;
      }
      .radio-group__icon path {
        fill: @primary;
        color: @primary;
      }
    }

    &:hover {
      border-color: @primary;
    }
  }

  &--vertical {
    flex-direction: column;

    & .radio-group__item:not(:last-child) {
      margin: 0 0 16px 0;
    }

    .radio-group__item--simplified {
      margin: 0 !important;
    }

    & .radio-group__btn {
      width: 100%;
    }
  }

  &--grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 12px;

    .screen-sm({
      grid-template-columns: 1fr;
    });

    & .radio-group__item:not(:last-child) {
      margin: 0;
    }
  }

  &__name {
    font-size: 18px;
    font-weight: 600;
    color: @gray-4;
    width: 100%;
    white-space: break-spaces;

    .screen-sm({
      font-size: 16px;
      line-height: 148%;
    });
  }

  &__after-btn {
    &:not(:empty) {
      margin: 10px 0;
      padding-left: 16px;

      > .ant-form-item:last-child {
        margin-bottom: 0px;
      }
    }

    &--simplified:not(:empty) {
      margin-top: 0;
      margin-bottom: 8px;
      padding-left: 24px;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';