@import 'assets/less/variables';
@import 'assets/less/colors';

.inbox-threads-sidebar {
  display: flex;
  flex-direction: column;
  flex: 0 0 280px;
  border-right: 1px solid @grey-300;
  box-shadow: 0 2px 6px 0 rgba(41,41,42,.07);
  background-color: @white;

  .screen-lg({
    flex: 0 0 100%;
    border-right: unset;
    box-shadow: unset;
  });

  &__empty-card {
    height: calc(100dvh - 182px);
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    padding: 16px 24px;
    margin: 0;
  }

  &__search-input {
    width: auto;
    gap: 12px;
    margin: 8px 16px;
    margin-top: 14px;
    border-radius: 6px;
    border: 1px solid @grey-300;

    &::before {
      content: unset;
    }

    &, & input {
      background-color: @grey-200;
    }

    svg {
      circle,
      line {
        stroke: @grey-600;
      }
    }
  }

  &__tabs {
    max-height: calc(100% - 62px);

    &__pane {
      max-height: 100%;
      overflow: auto;
    }

    .ant-tabs-nav {
      margin: 0px;
      box-shadow: 0px 5px 8px 0px rgba(122, 126, 161, 0.16);
      z-index: 1;
    }

    .ant-tabs-nav-list {
      flex: 1;
    }

    .ant-tabs-content {
      height: 100%;
    }

    .ant-tabs-tab {
      flex: 1;
      justify-content: center;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 16px;
    }

    .ant-tabs-tab-btn {
      font-size: 12px;
      font-weight: 600;
      line-height: 124%;
      text-transform: uppercase;
    }

    .ant-tabs-nav-operations {
      display: none !important;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';