// General colors
@light: #ffffff;
@dark: #091828;
@dark-2: #234361;

@blue: #5685ff;
@light-blue: #e7eeff;

@success: #22b02e;
@light-green: #dcffdf;

@error: #ff4d4f;

@red: #c5292a;
@peach-red: #ff7875;
@light-red: #ffc7c7;

@bgc-primary: #f9fafb;

@orange: #ff801f;
@light-orange: #ffeee1;

@purple: #853bfe;
@light-purple: #f5ebff;

// Main colors
@primary: #3b9cfe;
@primary-light: #f7fbff;
@primary-light-2: #e0f0ff;
@primary-light-3: #eaf3fb;
@primary-light-4: #ecf5ff;
@primary-light-5: #daecff;

@primary-medium: #87bfff;
@primary-dark: #438dd8;

// Shades of
@gray-1: #6b778c;
@gray-2: #cfd4dd;
@gray-3: #a7aeba;
@gray-4: #42526e;
@gray-5: #e2e4e5;
@gray-6: #f4f5f7;
@gray-7: #e7eaee;
@gray-8: #66788a;
@gray-9: #b8b8b8;

// General
@border-radius: 10px;
@border-radius-2: 8px;
@border-radius-3: 4px;
@border-radius-4: 12px;
@border-radius-5: 16px;
@border-radius-6: 2px;

@border-style-1: 1px solid rgba(207, 212, 221, 0.5);

@box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
@box-shadow-2: 0 2px 10px rgba(9, 24, 40, 0.12);
@box-shadow-3: 0px 2px 6px rgba(0, 0, 0, 0.04);
@box-shadow-4: 0 0 6px rgba(0, 0, 0, 0.06);

// Fonts
@font-family-primary: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
@font-family-secondary: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

// Media Queries
@xxl: 1599px;
@xl: 1199px;
@lg: 991px;
@md: 767px;
@sm: 575px;
@xs: 320px;

// Media Queries mixin
.screen-xxl(@rules) {
  @media (max-width: @xxl) {
    @rules();
  }
}
.screen-xl(@rules) {
  @media (max-width: @xl) {
    @rules();
  }
}
.screen-lg(@rules) {
  @media (max-width: @lg) {
    @rules();
  }
}
.screen-md(@rules) {
  @media (max-width: @md) {
    @rules();
  }
}
.screen-sm(@rules) {
  @media (max-width: @sm) {
    @rules();
  }
}
.screen-xs(@rules) {
  @media (max-width: @xs) {
    @rules();
  }
}
