@import 'assets/less/variables';

.organization {
  margin: 16px 24px;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #f4f5f7;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  height: calc(100% - 150px);
  color: @gray-4;

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__title {
    color: @dark;
    font-size: 16px;
    font-weight: 600;
  }

  &__edit-btn {
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
      margin-top: -3px;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    img {
      width: 104px;
      height: 104px;
      margin-right: 12px;
      border-radius: 12px;
    }
  }

  &__info-list-item {
    margin-bottom: 16px;
    font-size: 14px;
  }

  &__info-list {
    padding: 0;
    margin: 0 0 24px 0;
    list-style: none;
    max-width: 1024px;

    li {
      h3 {
        font-weight: bold;
        margin: 0 0 8px 0;
      }
      p {
        word-break: break-word;
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';