@import 'assets/less/variables';

.show-candidate-requirements {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 14px;

    .labeled-content__record {
      margin-bottom: 0px;
    }

    svg path {
      fill: @gray-4;
    }

    .ant-tag {
      border: none;
      padding: 0;
      background: #fff;
      font-weight: 600;
      font-size: 14px;
      white-space: initial;
      display: inline-flex;
      align-items: center;
      color: @gray-4;

      svg {
        margin-right: 5px;
        min-width: 24px;
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';