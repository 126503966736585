@import 'assets/less/variables';
@import 'assets/less/colors';

.add-candidate-requirements {
  &__defaultValue {
    position: relative;
    top: -16px;
    display: inline-block;
    margin: 0 0 10px 0;
    padding: 1px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: @primary;
    background: @primary-light;

    border: 1px solid @primary;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &__years-counter {
    width: 100%;

    .ant-input-number-group-addon {
      background-color: @white;
    }
  }

  &__specialities-wrapper {
    margin-bottom: 24px;

    &__radio-group {
      .ant-form-item-control-input {
        min-height: unset;
      }

      &__options {
        .ant-radio-wrapper {
          gap: 8px;
          align-items: flex-start;
          margin-right: 32px;

          &:after {
            display: none;
          }

          span {
            &:first-child .ant-radio-inner {
              width: 24px;
              height: 24px;
            }

            &:last-child {
              color: @dark;
              font-size: 16px;
              font-weight: 400;
              line-height: 148%;
              padding: 4px 0 0;
            }
          }
        }
      }
    }
  }

  &__speciality-row {
    width: 709px;
    display: flex;
    align-items: flex-start;
    gap: 8px;

    &:first-of-type .add-candidate-requirements__delete-icon {
      margin-top: 29px;
    }

    &:not(:first-of-type) {
      margin-top: 8px;
    }

    &__counter {
      .ant-input-number-group-addon {
        background-color: @white;
      }
    }

    &__years-counter {
      .custom-counter__button {
        width: auto;
      }

      .ant-input-number-group-addon {
        background-color: @white;
      }
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  &__qualifications {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    gap: 8px;

    &__label {
      color: @gray-4;
      font-size: 14px;
      font-weight: 400;
    }

    &__row {
      display: flex;
      flex-flow: row;
      align-items: flex-start;
      gap: 8px;
    }
  }

  &__row-item {
    width: 384px;

    &--unlimited {
      width: 100%;
    }

    &--shortened {
      width: 338px !important;
      flex: 1 0 338px;
    }

    &--shortest {
      width: 287px !important;
      flex: 1 0 338px;
    }

    &--required {
      flex: 0 0 180px;
    }

    &--experience-in-years {
      flex: 0 0 144px;
    }

    .ant-input-number {
      width: 100% !important;
    }

    .ant-input-number-input {
      text-align: center;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  &__text-button {
    height: fit-content;
    padding: 0;
    margin-top: 14px;
    border: none;
    color: @primary;
    font-size: 16px;
    font-weight: 600;
    line-height: 148%;

    &--inverse-margin {
      margin-top: 0;
      margin-bottom: 24px;
    }
  }

  &__delete-icon {
    width: fit-content;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    border: none;

    svg {
      width: 24px;
      height: 24px;

      path:not(:last-child) {
        fill: @red;
      }
      path:last-child {
        fill: @white;
      }
    }
  }

  &__multiple-select {
    position: relative;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';