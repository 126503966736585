@import 'assets/less/variables';

.vacancy-detail {
  display: flex;

  &__main {
    flex: 1;
    margin-right: 24px;
  }

  &__details {
    display: flex;
    flex-direction: column;
  }

  &__details-block {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__column {
    border: 1px solid rgba(207, 212, 221, 0.5);
    box-sizing: border-box;
    border-radius: 16px;
    padding: 24px;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }

    .labeled-content__record:last-child {
      margin-bottom: 0px;
    }

    .ant-tag {
      border: none;
      padding: 0;
      margin-top: 12px;
      background: #fff;
      font-weight: 600;
      font-size: 14px;
      white-space: initial;
      display: inline-flex;
      align-items: center;
      color: @gray-4;
      svg {
        margin-right: 5px;
        min-width: 24px;
        min-height: 24px;
      }
    }
  }

  &__description {
    font-size: 14px;
    color: @gray-4;
  }

  &__tags {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    margin: 0;
  }
  &__second_title {
    font-weight: 600;
    font-size: 16px;
    margin: 16px 0 8px 0;

    &:first-child {
      margin: 0px 0 8px 0;
    }
  }

  &__info-block {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: bold;
    &:last-child {
      margin-bottom: 0;
    }

    h3 {
      color: @gray-1;
      font-weight: bold;
      font-size: 12px;
      margin: 0;
    }
    p {
      margin: 0;
      font-size: 14px;
      color: @gray-4;
    }
    svg {
      margin-right: 8px;
      path {
        fill: @gray-1;
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';