@import 'assets/less/variables';

.forgot-password {
  &__card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 560px;
    text-align: left;
    padding: 40px;

    .screen-sm({
      flex-direction: column;
      padding: 24px;
    });
  }

  &__form .ant-input {
    .screen-sm({
      font-size: 14px;
    });
  }

  &__divider {
    height: 480px;
    margin: 32px;
    width: 1px;
    background-color: @gray-6;

    .screen-sm({
      height: 1px;
      width: 100%
    });
  }

  &__img {
    width: 204px;
  }

  &__title {
    margin-bottom: 8px;
    line-height: 36px;
  }

  &__subtitle {
    max-width: 424px;
    margin-bottom: 16px;
  }

  .screen-sm({
    min-height: 400px;
  });

  &__form {
    padding: 32px;
    border: 1px solid @gray-5;
    border-radius: @border-radius-2;

    .screen-sm({
      padding: 16px;
    });
  }

  &__resend-button {
    width: 100%;
    margin-top: 8px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';