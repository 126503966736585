@import 'assets/less/variables';

.contact-link {
  display: flex;
  align-items: center;

  &__vertical {
    flex-direction: column;
  }

  &__text {
    margin-left: 10px;
    color: @gray-1;

    &--colored {
      color: @primary;
    }

    .screen-sm({
      font-size: 14px;
    });
  }

  &:hover &__text {
    text-decoration: underline;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: @primary;
    }
  }

  &__icon-margin {
    margin-bottom: 14px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';