@import 'assets/less/variables';

.email-preferences {
  position: relative;
  height: 100%;
  background-color: @bgc-primary;

  .screen-lg({
    overflow: hidden;
  });

  &__background {
    z-index: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: 100%;

    .screen-lg({
      top: unset;
      bottom: -475px;
    });
  }

  &__logo {
    width: 288px;
    height: auto;
    margin-bottom: 49.14px;

    .screen-lg({
      width: 140px;
      height: auto;
    });
  }

  &__wrapper {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 197px 104px 0 161px;
    overflow: auto;

    .screen-lg({
      height: 100%;
      flex-direction: column;
      padding: 66px 16px 66px 16px; 
    });

    div:first-child {
      height: fit-content;
    }

    img:last-child {
      width: 395px;
      height: 393px;
      margin: 208px 0 0 83px;

      .screen-lg({
        width: 280px;
        height: 280px;
        margin: 92px auto 0 auto;
      });
    }
  }

  h1 {
    line-height: 48px;
    font-weight: 600;
    font-size: 30px;
    color: @dark;
    margin-bottom: 12px;

    .screen-lg({
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 4px;
    });
  }

  h2 {
    line-height: 36px;
    font-weight: 400;
    font-size: 24px;
    color: @dark;
    margin-bottom: 44px;

    .screen-lg({
      font-size: 16px;
      line-height: 24px;
    });
  }

  .ant-form-item {
    margin-bottom: 36px;
  }

  .ant-tree-list {
    background: @bgc-primary;
  }

  .ant-tree-treenode {
    margin-bottom: 24px;
  }

  .ant-tree-checkbox + span {
    line-height: 32px;
    font-weight: 600;
    font-size: 20px;

    .screen-lg({
      line-height: 24px;
      font-size: 16px;
    });
  }

  .ant-tree-checkbox-inner {
    width: 24px;
    height: 24px;
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
    top: 12px;
    transform: rotate(45deg) scale(1.6) translate(-50%, -50%);
  }

  .ant-btn {
    padding: 0px 40px;

    .screen-lg({
      display: block;
      margin: 0 auto;
    });
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';