@import 'assets/less/variables';

.talents-tab {
  width: 100%;

  .ant-table {
    font-size: 14px;
    color: @dark-2;
  }

  .ant-table-thead {
    .ant-table-cell {
      padding: 10px 16px;
      background: #fff;
      text-transform: uppercase;
      border-bottom: 1px solid @gray-2;
      color: @gray-8;
      font-weight: bold;
    }
  }

  .ant-table-row {
    .ant-table-cell {
      padding: 24px 16px;
    }
    &:nth-child(even) {
      background: @primary-light;
      border-bottom: 1px solid @gray-2;
    }
  }

  &__view-more-btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }

  &__progress {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 46px;
    margin: 0px 10px;
    font-size: 12px;
    line-height: 20px;
    color: @primary;
    background: @primary-light-5;
    border-radius: @border-radius-6;
  }

  &__filter-btn {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 6px;
    color: @gray-4;
    box-shadow: none;

    &-active {
      background: @primary-light-4;
      color: @primary;

      svg {
        path {
          fill: @primary;
        }
      }
    }

    svg {
      margin-right: 10px;
      path {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    &:focus,
    &:hover {
      background: @primary-light-4;

      svg {
        path {
          fill: @primary;
        }
      }
    }

    &__count {
      margin-left: 5px;
      background: @primary;
      color: #fff;
      padding: 2px 6px;
      border-radius: 50%;
      font-size: 10px;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';