@import 'assets/less/colors';

.tag-number {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;

  &__button {
    height: auto;
    width: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    border: 1px solid @blue-700;
    background: @blue;
    color: @white;
    padding: 4px;

    &--inactive {
      border: 1px solid @grey-300;
      background: @grey-400;

      &:focus,
      &:hover {
        background: @grey-300;
      }
    }

    &:focus,
    &:hover {
      background: @blue-700;
      color: @white;
    }

    svg path {
      fill: @white;
    }
  }

  &__value {
    width: 50px;
    color: @grey-900;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 148%;
  }

  .ant-input-number input {
    text-align: center;
    color: @grey-700;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';