@import 'assets/less/variables';
@import 'assets/less/colors';

.add-items {
  display: flex;
  flex-flow: column;
  gap: 8px;
  background: @light;

  .screen-md({
    padding: 20px 14px;
  });

  &__search-input {
    padding: 8px 10px;
    border-radius: 6px;
    border: 1px solid @grey-300;
    background: @grey-200;
    color: @gray-3;
    font-size: 14px;
    font-weight: 500;

    &__icon {
      width: 16px;
      height: 16px;
    }

    .ant-input {
      background: @grey-200;
    }

    .ant-input-prefix {
      margin-right: 12px;
    }
  }

  &__checkbox-group {
    max-height: 510px;
    overflow-y: auto;

    @media (max-height: 890px) {
      max-height: 400px;
    }

    @media (max-height: 790px) {
      max-height: 320px;
    }

    @media (max-height: 690px) {
      max-height: 248px;
    }
    @media (max-height: 568px) {
      max-height: 130px;
    }

    &--taller {
      @media (min-height: 891px) {
        max-height: 430px;
      }
      @media (max-height: 890px) {
        max-height: 345px;
      }

      @media (max-height: 790px) {
        max-height: 270px;
      }

      @media (max-height: 690px) {
        max-height: 248px;
      }
      @media (max-height: 568px) {
        max-height: 75px;
      }
    }

    &--empty-state {
      min-height: 225px;
    }
  }

  &__no-search-results {
    position: absolute;
    top: 63%;
    left: 37.5%;
    color: @gray-4;
    font-size: 16px;
    font-weight: 400;
    line-height: 156%;
  }

  &__sub-title {
    color: @gray-1;
    font-size: 14px;
    font-weight: 400;
    line-height: 156%;
    margin-bottom: 0px;

    .screen-sm({
      font-size: 12px;
      line-height: 124%;
    });
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';