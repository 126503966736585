@import 'assets/less/variables';

.add-vacancy-information {
  .talent-modal-form__content {
    .ant-form-item {
      margin-bottom: 16px;

      .ant-form-item-label {
        padding-bottom: 6px;
      }
    }
  }

  &__defaultValue {
    position: relative;
    top: -16px;
    display: inline-block;
    margin: 0 0 10px 0;
    padding: 1px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: @primary;
    background: @primary-light;

    border: 1px solid @primary;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &__radio-group-options {
    .ant-radio-wrapper {
      gap: 8px;
      align-items: flex-start;
      margin-right: 32px;

      &:after {
        display: none;
      }

      span {
        &:first-child .ant-radio-inner {
          width: 24px;
          height: 24px;
        }

        &:last-child {
          color: @dark;
          font-size: 16px;
          font-weight: 400;
          line-height: 148%;
          padding: 4px 0 0;
        }
      }
    }
  }

  &__employment_type {
    gap: 24px;
  }

  &__shift-pattern {
    gap: 24px;
  }

  &__form {
    &__checkbox {
      display: flex;
      align-items: flex-start;

      span {
        &:first-child {
          top: 5px;
        }
        &:last-child {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &__visa-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;

    &__popover {
      cursor: pointer;
      &:hover {
        path {
          fill: @primary;
        }
      }

      &__content {
        color: @dark;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 156%;

        width: 370px;
      }
    }
  }

  &__benefits {
    &-wrapper {
      margin-bottom: 32px;
    }
  }

  &__divider {
    background-color: @gray-6;
  }

  &__salary-wrapper {
    margin-bottom: 0px !important;

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: space-between;
      width: 342px;
    }
    .ant-input-number-affix-wrapper {
      width: 100% !important;
      padding: 0;

      .ant-input-number {
        width: 100% !important;
      }
      .ant-input-number-input {
        padding-left: 11px !important;
      }
    }
  }

  &__financial-benefits {
    .ant-form-item-control-input-content {
      display: flex;
    }

    .ant-form-item-control-input {
      min-height: auto;
    }
  }

  &__welcome-bonus {
    &__item {
      margin-bottom: 0 !important;

      .ant-form-item-control-input {
        min-height: 30px;
        align-items: start;
      }
    }

    &__input {
      width: 332px;

      .screen-sm({
      width: 36vh;
      bottom: 1.25rem;
      });
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';