@import 'assets/less/variables';

.image-upload-preview {
  &__uploader-wrapper {
    display: flex;
    cursor: default;

    .ant-btn {
      display: flex;
      padding-left: 0;
    }
    .ant-btn-dangerous {
      &:hover {
        color: #c5292a;
      }
    }

    svg {
      margin-right: 6px;
    }
  }

  &__image {
    width: 104px;
    height: 104px;
    margin-right: 12px;
    border-radius: @border-radius-4;
  }

  &__pointer {
    cursor: pointer;
  }

  &__constraints {
    margin: 0;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';