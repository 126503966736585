@import 'assets/less/variables';
@import 'assets/less/colors';

.create-vacancy {
  margin: 16px 24px;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #f4f5f7;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  height: calc(100% - 150px);
  color: @gray-4;

  &__form {
    width: 100%;
    max-width: 540px;
    &-actions {
      .ant-btn {
        width: 170px;
      }
    }

    .ant-input-number-group-addon {
      padding: 0;
    }
  }

  &__years-counter {
    width: 100%;

    .ant-input-number-group-addon {
      background-color: @white;
    }
  }

  &__row,
  &__speciality-row {
    width: 540px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-form-item {
      margin-bottom: 0;
    }
  }
  &__speciality-row {
    align-items: flex-start;
    gap: 8px;

    &:first-of-type .create-vacancy__delete-icon {
      margin-top: 29px;
    }

    &:not(:first-of-type) {
      margin-top: 8px;
    }

    &__years-counter {
      .ant-input-number-group-addon {
        background-color: @white;
      }
    }

    &__option {
      &__badge {
        padding: 2px 4px;
        margin-right: 6px;
        border-radius: 25px;
        border: 1px solid @grey-300;
        background: @grey-200;
        color: @grey-600;
        font-size: 12px;
        font-weight: 600;
        line-height: 124%;
        text-transform: uppercase;
      }

      &__label {
        color: @grey-900;
        font-size: 14px;
        font-weight: 400;
        line-height: 100%;
      }
    }
  }

  &__specialities-wrapper {
    margin-bottom: 24px;

    &__radio-group {
      .ant-form-item-control-input {
        min-height: unset;
      }

      &__options {
        .ant-radio-wrapper {
          gap: 8px;
          align-items: flex-start;
          margin-right: 32px;

          &:after {
            display: none;
          }

          span {
            &:first-child .ant-radio-inner {
              width: 24px;
              height: 24px;
            }

            &:last-child {
              color: @dark;
              font-size: 16px;
              font-weight: 400;
              line-height: 148%;
              padding: 4px 0 0;
            }
          }
        }
      }
    }
  }

  &__icon-wrapper {
    width: 22px;
    svg {
      width: 100%;
    }
  }

  &__delete-icon {
    width: fit-content;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    border: none;

    svg {
      width: 24px;
      height: 24px;

      path:not(:last-child) {
        fill: @red;
      }
      path:last-child {
        fill: @white;
      }
    }
  }

  &__row-item {
    width: 241px;

    &--unlimited {
      width: 100%
    }

    &--shortened {
      width: 195px;
      flex: 1 0 195px;
    }

    &--no-label {
      .ant-form-item-label {
        display: none;
      }
    }

    &--required {
      flex: 0 0 180px;
    }

    &--experience-in-years {
      flex: 0 0 125px;
    }

    .ant-input-number {
      width: 100% !important;
    }

    .ant-input-number-input {
      text-align: center;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  &__experience_button {
    background: #fff;
    padding: 0;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      path {
        fill: #091828;
      }
    }
  }

  &__assignee-option {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    p {
      margin: 0;
    }
  }

  &__facility-dropdown {
    .ant-select-item-option {
      padding: 8px 16px;

      &-active {
        background-color: @blue-100;

        span {
          &:last-child {
            color: @primary;
          }
        }
      }
    }
  }

  &__assignee-vacancies {
    color: @gray-1;
  }

  &__text-button {
    height: fit-content;
    padding: 0;
    margin-top: 14px;
    border: none;
    color: @primary;
    font-size: 16px;
    font-weight: 600;
    line-height: 148%;
  }

  &__multiple-select {
    position: relative;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';