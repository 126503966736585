@import 'assets/less/variables';

.footer {
  background-color: #f6fbff;

  .screen-sm({
    background-color: transparent;
  });

  &__wrapper {
    margin: 0 auto;
    max-width: 1134px;
    display: flex;
    flex-flow: column wrap;
    padding: 24px 0 72px 0;

    .screen-sm({
      border-top: 1px solid @gray-2;
      padding-top: 32px;
    });

    .screen-lg({
      max-width: unset;
      flex-direction: column;
    });
  }

  .screen-lg({
    padding: 0 32px;
  });

  &__logo-wrapper {
    .screen-lg({
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
    });
  }

  &__logo {
    width: 200px;
    height: 33px;
    margin-bottom: 32px;

    .screen-lg({
      width: 180px;
      height: 47px;
      margin-bottom: 74px;
    });
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: @gray-4;
    margin: 0;

    .screen-lg({
      text-align: center;
      font-size: 10px;
    });
  }

  &__container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .screen-lg({
      justify-content: center;
    });
  }

  &__contacts {
    display: flex;
    flex-flow: column wrap;
    gap: 16px;

    &__title {
      color: @gray-4;
      font-weight: 600;
      line-height: 24px;
    }

    &__links {
      display: flex;
      flex-flow: column wrap;
      gap: 20px;
      padding: 0 6px;
      margin-bottom: 20px;

      .screen-lg({
        gap: 16px;
      });

      .contact-link {
        margin: 0;
        font-size: 16px;
        line-height: 24px;

        &__text {
          margin-left: 12px;
        }
      }

      .screen-lg({
      flex-direction: column;
      align-items: center;
      margin-bottom: unset;
    });
    }
  }

  &__locations {
    display: flex;
    flex-flow: column nowrap;
    flex-basis: 50%;
    gap: 16px;

    &__title {
      color: @gray-4;
      font-weight: 600;
      line-height: 24px;
    }

    &__links {
      display: flex;
      flex-flow: row wrap;
      gap: 16px;

      &__item {
        flex-basis: 31%;
        color: @gray-4;
        line-height: 24px;

        &:hover {
          color: @gray-3;
        }
      }
    }
  }

  &__rights {
    text-align: center;

    &&--margins {
      margin-top: 88px;

      .screen-lg({
        margin-top: 32px;
      });
    }
  }

  &__link {
    margin-left: 42px;

    .screen-lg({
      margin-left: unset;
      margin-bottom: 20px;
    });
  }

  &__last-link {
    margin-left: 42px;

    .screen-lg({
      margin-left: unset;
      margin-bottom: 32px;
      text-align: center;
    });
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';