@import 'assets/less/variables';
@import 'assets/less/colors';

.candidates-card-dropdown-menu {
  .ant-dropdown-menu,
  &__loading-wrapper {
    background-color: #fff;
    border-radius: @border-radius-2;
    box-shadow: @box-shadow-2;
  }

  &__loading-wrapper {
    width: 143px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__spin {
    margin-left: 8px;
    svg {
      path {
        color: @dark;
      }
    }
  }
}

.candidates-card {
  &__wrapper {
    position: relative;
    width: 338px;
    height: 252px;
    padding: 16px;
    background: @light;
    border: 1px solid #f4f5f7;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: @border-radius-5;
  }

  &__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: @dark;
  }

  &__status {
    display: flex;
    align-items: center;
    height: 36px;
    position: absolute;
    top: -1px;
    right: -1px;
    padding: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    border: none;
    border-radius: 0 @border-radius-5;
  }

  &__category {
    display: inline-flex;
    margin: 12px 8px 0 0;
    padding: 0 8px;
    height: 32px;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: @gray-1;
    background: @gray-6;
    border-radius: 4px;
  }

  &__additional-category {
    margin-right: 0;
  }

  &__divider {
    margin: 8px 0;
  }

  &__salary-range {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: @gray-1;
  }

  &__record {
    display: inline-flex;
    align-items: center;
    margin-top: 8px;

    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: @gray-1;
  }

  &__records-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__category-wrapper {
    display: flex;
    flex-direction: row;
    letter-spacing: -0.3px;
  }

  &__icon {
    margin-right: 10px;
    height: 18px;
    width: 18px;

    path {
      fill: @gray-1;
    }
  }

  &__specialities-icon {
    margin-right: 8px;
    width: 16px;
    path {
      fill: @gray-1;
    }
  }

  &__buttons {
    display: flex;
    flex-flow: row;
    gap: 8px;

    &__view {
      margin-top: 8px;
      width: 100%;
    }

    &__send-message {
      align-items: center;
      display: flex;
      gap: 8px;
      margin-top: 8px;
      width: 100%;
      border: none;
      background: @blue-200;
      color: @primary;
    }
  }
}

.candidates {
  &__new-match,
  &__interest-sent {
    color: @blue;
    background: @light-blue;

    svg {
      path {
        fill: @blue;
      }
    }
  }

  &__interest-accepted,
  &__hired {
    color: @success;
    background: @light-green;

    svg {
      path {
        fill: @success;
      }
    }
  }

  &__shortlisted,
  &__interview-pending {
    color: @primary;
    background: @primary-light-4;

    svg {
      path {
        fill: @primary;
      }
    }
  }

  &__offer-extended {
    color: @purple;
    background: @light-purple;

    svg {
      path {
        fill: @purple;
      }
    }
  }

  &__match-declined,
  &__not-hired {
    color: @red;
    background: @light-red;
  }

  &__interest-declined {
    color: @orange;
    background: @light-orange;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';