@import 'assets/less/variables';

.sign-up-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('~assets/images/sign-up-block-bg.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  padding: 64px 0;
  margin-bottom: 40px;

  .screen-lg({
    background-image: url('~assets/images/sign-up-block-bg-mobile.png');
    padding: 64px 24px 52px;
    margin-bottom: 0;
  });

  &__title {
    color: @light;
    font-size: 28px;
    font-weight: 700;
    line-height: 132%;
    margin-bottom: 4px;

    .screen-lg({
      margin-bottom: 6px;
      font-size: 24px;
      line-height: 156%;
    });
  }

  &__certificates {
    margin-bottom: 32px;

    .screen-lg({
      margin-bottom: 16px;
      flex-flow: row nowrap;
      gap: 12px;
    });
  }

  &__action-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 32px;
    border-radius: 4px;
    background: @light;
    color: @primary;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 148%;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';