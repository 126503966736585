@import 'assets/less/colors';

.avatar {
  .common() {
    color: @blue;
    background-color: @blue-400;
    font-weight: 600;
    overflow: initial;
    user-select: none;
  }

  .size(@value) {
    width: @value;
    height: @value;
    min-width: @value;
    line-height: @value;
    font-size: calc(@value * 0.375);
  }

  &--small {
    .common();
    .size(32px);
  }

  &--medium {
    .common();
    .size(40px);
  }

  &--large {
    .common();
    .size(70px);
  }

  &--curved {
    border-radius: 16px;
  }

  &--online:after {
    content: '';
    position: absolute;
    bottom: 2px;
    right: 2px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    outline: 2px solid @white;
    background-color: @green-500;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';