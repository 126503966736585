@import 'assets/less/variables';

.vacancies-filter {
  &__international {
    .ant-form-item-row {
      flex-flow: row;
      gap: 16px;
      align-items: baseline;

      .ant-form-item-label {
        flex: 1 0 auto;
      }

      .ant-form-item-control-input {
        min-height: unset;
      }
    }

    &__switch {
      .ant-switch-inner {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__icon {
        &--checked {
          margin-top: 3px;
        }

        & path {
          fill: @light;
          transition: fill 0.3s;
        }
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';