@import 'assets/less/variables';

.profile-header {
  .message-counter() {
    margin-left: 8px;

    .ant-badge-count {
      background-color: @orange;

      .ant-scroll-number-only-unit {
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
      }
    }
  }

  position: relative;
  z-index: 1;
  background: #fff;
  height: 80px;
  margin-bottom: 16px;

  .screen-lg({
    height: 66px;
  });

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    height: 80px;

    .screen-lg({
      justify-content: space-between;
      height: 66px;
    });
  }

  &__burger {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-left: -10px;
    border: none;
    outline: none;
    background: inherit;

    svg {
      flex-shrink: 0;
    }
  }

  &__logo-wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: auto;

    .screen-sm({
      flex: 1 0 auto;
    });
  }

  &__logo {
    width: 146px;
    height: 37px;

    .screen-lg({
      width: 200px;
      height: 33px;
      margin-left: -40px;
    });
  }

  &__nav {
    margin-left: 32px;
    display: flex;

    .ant-dropdown-menu,
    .ant-dropdown-menu-submenu-popup {
      border-radius: @border-radius-2;
      margin-top: 12px;
      padding: 15px 12px 8px 12px;

      a,
      .ant-dropdown-menu-submenu-title {
        display: block;
        padding: 5px 12px;
        margin: 0 32px 12px 0 !important;
      }
      .ant-dropdown-menu-submenu-expand-icon {
        top: 3px;
      }
    }
  }

  &__nav-item {
    display: flex;
    align-items: center;
    margin-right: 32px;
    font-size: 14px;
    line-height: 24px;
    color: @gray-4;

    &__message-counter {
      .message-counter();

      &--red {
        margin-right: 8px;

        svg:first-child {
          margin-right: unset !important;
        }

        .ant-badge-dot {
          background-color: @red;
          top: 2px;
          right: 2px;
        }
      }

      &--positioned {
        .ant-badge-dot {
          top: 13px;
          right: 10px;
        }
      }
    }

    svg:first-child {
      width: 16.5px;
      height: auto;
      margin-right: 8px;

      path {
        fill: currentColor;
      }
    }

    &:not(&--active):hover {
      svg {
        path {
          fill: @primary;
        }
      }
      color: @primary;
      cursor: pointer;
    }

    &--active {
      font-weight: 600;
      color: @primary;
      pointer-events: none;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;

    .screen-lg({
      margin-left: 0;
    });
  }

  &__refer-a-friend {
    margin-right: 24px;

    .screen-lg({
      margin: 24px;
    });

    svg {
      margin-right: 10px;

      path {
        fill: #ffffff;
      }
    }
  }

  &__menu {
    box-shadow: @box-shadow-2;
    border-radius: @border-radius-2;
  }

  &__menu-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: @gray-4 !important;
    padding: 12px 16px;

    svg {
      margin-right: 12px;
    }

    &:hover {
      background: @primary-light;
    }

    &--active {
      svg {
        path {
          fill: @primary;
        }
      }

      font-weight: 600;
      color: @primary !important;
    }
  }

  &__nav-arrow {
    position: relative;
    top: -2px;
    margin-left: 4px;
  }

  &__drawer {
    &__logo-wrapper {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      width: 140px;
      height: auto;

      .screen-sm({
        flex: 1 0 auto;
      });
    }

    &__logo {
      width: 134px;
      height: 37px;

      .screen-lg({
        width: 200px;
        height: 33px;
        margin-left: -36px;
      });
    }

    .ant-drawer-header {
      padding: 24px;
      border-bottom: none;
    }

    .ant-drawer-close {
      margin-right: 0;
    }

    .ant-drawer-title {
      display: flex;
    }

    .ant-drawer-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;
    }

    .ant-menu-inline {
      border: none;
      background: none;
    }

    .ant-menu-submenu-title {
      margin-top: 6px;
      margin-bottom: 6px;
      width: 100%;

      svg {
        path {
          fill: @gray-4;
        }
      }

      .ant-menu-title-content {
        color: @gray-4;
      }
    }

    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow::after {
      color: @gray-4;
    }

    .profile-header__drawer-menu a {
      font-size: 16px;
      line-height: 40px;
      border: none;
      padding: 4px 24px 0px;
      margin-bottom: 10px;

      &.active {
        font-weight: 600;
        color: @primary;

        svg {
          path {
            fill: @primary;
          }
        }
      }

      svg {
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }
    }
  }

  &__drawer-item {
    display: flex;
    align-items: center;
    margin-right: 0;
    padding: 14px 24px;
    color: @gray-4;
    transition: 0.3s;

    &__message-counter {
      .message-counter();
    }

    svg {
      width: 18px;
      height: auto;
      margin-right: 8px;
      path {
        fill: @gray-4;
      }
    }

    &--active {
      font-weight: 600;
      color: @primary;

      svg {
        path {
          fill: @primary;
        }
      }
    }
  }

  &__user-info {
    display: flex;
  }

  &__first-letter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-right: 8px;
    font-size: 20px;
    font-weight: 600;
    color: @primary;
    background-color: @primary-light-4;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';