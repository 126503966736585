@import 'assets/less/variables';

.dashboard-list {
  display: flex;
  flex-wrap: wrap;
}

.dashboard-item {
  border: 1px solid @gray-2;
  border-radius: 8px;
  padding: 22px;
  height: 120px;
  text-align: center;
  width: 260px;
  margin: 0 16px 16px 0;

  &__title {
    font-size: 14px;
    line-height: 16px;
    color: @gray-4;
  }

  &__count {
    font-size: 38px;
    line-height: 56px;
    font-weight: 600;
    margin: 0;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';