@import 'assets/less/variables';

.vacancy-match-modal {
  &__title {
    display: flex;
    gap: 8px;
  }

  &__badge {
    color: @blue;
    font-weight: 600;
    background: @light-blue;
    border: none;
    padding: 4px 6px;
  }

  &__label {
    color: @gray-4;
    font-style: italic;
    font-weight: 700;
  }

  &__table {

    &__column {
      &__job-title {
        display: flex;
        flex-flow: column;

        span:first-child {
          font-weight: 600;
        }
      }
    }

    .ant-table-body {
      overflow-y: auto !important;
    }

    .ant-table-thead {
      .ant-table-cell {
        background: #fff;
        font-size: 12px;
        font-weight: 700;
        color: @gray-1;
      }
      .ant-table-cell::before {
        display: none;
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        height: 48px;
        font-size: 14px;
      }
    }
  }

  &__empty-state {
    flex-direction: row;
    justify-content: flex-start;
    gap: 24px;
    padding: 0;

    &__requisites {
      align-items: flex-start;
      gap: 12px;
    }

    &__description {
      text-align: unset;
    }
  }

  &__alert {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    margin-top: 24px;
    border-radius: 24px;
    border: 1px solid @primary-light-2;
    background: #f6fbff;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;

    .ant-alert-icon {
      margin-right: 20px;
    }

    .ant-alert-message {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .ant-checkbox-inner {
    border-radius: 4px;
    border: 2px solid @gray-2;
    width: 24px;
    height: 24px;
  }

  .ant-tag {
    border: none;
    background: @primary-light-5;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';