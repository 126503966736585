@import 'assets/less/variables';

.hp-user-dashboard {
  padding: 24px;

  &__filter {
    .ant-form-item {
      width: 280px;
      margin-right: 24px;
    }
    .ant-form-item:last-child {
      margin-right: 0;
    }
    .ant-row {
      display: unset;
    }
    .ant-form-item-label > label {
      height: unset;
    }
    .ant-picker-input > input {
      border: unset;
    }
    .ant-form-item-has-success .ant-select .ant-select-selector {
      border: 1px solid #d9d9d9;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: @gray-4;
    margin-bottom: 32px;
  }

  &__wrapper {
    border-radius: @border-radius-5;
    background: #ffffff;
    padding: 32px 32px 48px 32px;
    margin-bottom: 24px;
    overflow: hidden;

    .ant-btn {
      border-radius: unset;
      box-shadow: unset;
    }
    .ant-btn:focus,
    .ant-btn:active {
      background: none;
      border: none;
    }
    .ant-btn:hover {
      background: #f9fbff;
    }
  }

  &__buttons-wrapper {
    border-bottom: 2px solid @gray-6;
    margin-bottom: 30px;
  }

  &__status-button {
    width: 20%;
    max-width: 218px;
    border: 0;
    padding: 24px;
    background-color: #fff;
    cursor: pointer;

    div:first-child {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    svg {
      width: 13px;
      height: auto;
      margin-right: 6px;

      path {
        fill: @gray-3;
      }
    }

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: @gray-3;
    }

    div:last-child {
      text-align: left;
      font-weight: 600;
      font-size: 32px;
      line-height: 32px;
      color: @dark;
    }
  }

  &__active {
    svg {
      path {
        fill: @gray-4;
      }
    }
    span {
      color: @gray-4;
    }
    div:last-child {
      color: @primary;
    }
    background-color: @primary-light-4;
    border-bottom: 2px solid @primary;
  }

  &__spin {
    display: block;
    margin: 0 auto;
  }

  &__overview-wrapper {
    height: 368px;
    margin-left: -32px;
  }

  &__velocity-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__velocity-chart-wrapper {
    width: calc(100% - 436px);
  }

  &__average-time-to-hire {
    margin-bottom: 12px;
    width: fit-content;
    border-radius: @border-radius-2;
    background-color: @primary;
    padding: 10px 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #fff;

    span {
      margin-left: 8px;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 100px;
      padding: 0 6px;
    }
  }

  &__velocity-chart {
    min-width: 565px;
    height: 528px;
    margin-left: -32px;
  }

  .ant-table-wrapper {
    max-width: unset;
    width: 420px;
  }

  .ant-table-thead > tr > th {
    background: none;
  }

  .ant-table-thead {
    .ant-table-cell {
      padding: 6px 16px;
      color: @dark-2;
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      padding: 0 0 0 16px;
      color: @gray-1;
    }
  }

  .ant-table-cell {
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  &__filled-column {
    width: 20px;
    height: 40px;
    border-radius: @border-radius-3;
    margin-bottom: 7px;

    &:first-child {
      margin: 2px 0 5px 0;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';