@import 'assets/less/variables';

.cookie-popup {
  max-width: 744px;
  width: 100%;
  padding: 24px;
  position: fixed;
  bottom: 8px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  background-color: @light;
  z-index: 3;
  border-radius: @border-radius-5;
  background: @light;
  box-shadow: @box-shadow-2;

  &-disabled {
    display: none;
  }

  .screen-lg({
    width: 100%;
    max-width: 444px;
    margin: 0 auto;
  });

  .screen-sm({
    width: 100%;
    max-width: 340px;
    margin: 0 auto;
  });

  &__button {
    min-width: 148px;
    .screen-lg({
      width: 100%;
      margin-top: 12px;
    });
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    .screen-lg({
      flex-direction: column;
    });
  }

  &__sub-title {
    text-align: start;
  }

  &__title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 4px;
    color: @dark;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';