@import 'assets/less/variables';
@import 'assets/less/colors';

.edit-qualifications {
  &__registration-path-form-item {
    margin-bottom: 24px !important;

    .ant-form-item-label {
      label {
        color: @dark;
        font-size: 16px;
        font-weight: 400;
        line-height: 148%;
      }
    }

    .ant-form-item-row {
      flex-flow: column;
    }
  }

  &__divider {
    margin: 0 0 12px;
    background-color: @grey-300;
  }

  &__qualification-type {
    margin-top: 10px;

    &__radio {
      display: flex;
      flex-flow: column;
      gap: 10px;
    }
  }

  &__plab-stages {
    margin-top: 10px;

    .ant-form-item-control {
      margin-top: 10px;
    }
  }

  &__qualification {
    margin-top: 24px;

    .ant-form-item-label {
      label {
        color: @grey-900;
        font-size: 16px;
        font-weight: 600;
      }
    }

    &__status {
      margin-top: 12px;
      margin-bottom: 0 !important;

      .form-item-with-radio();
    }

    &__independent-prescriber {
      .form-item-with-radio();
    }
  }
}
.form-item-with-radio() {
  &:last-child {
    margin-bottom: 24px !important;
  }

  &__radio {
    display: flex;
    gap: 32px;

    span:not(.ant-radio) {
      line-height: 148%;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';