@import 'assets/less/variables';

.vacancies {
  height: calc(100% - 156.42px);
  position: relative;

  .ant-tabs-nav {
    background: #fff;
    padding-left: 24px;
    margin: 0;
  }

  .ant-tabs-content {
    padding: 24px;
  }

  .ant-tabs-tab-btn {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: @gray-4;
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 200px);
    max-width: 560px;
    margin: auto;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__vacancy {
    margin: 0 24px 24px 0;
  }

  .ant-pagination {
    margin-top: 24px;
    text-align: right;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-item {
    border: transparent;
    background: transparent;
  }

  &__icon {
    width: 140px;
    height: 162px;
  }

  &__filter-btn {
    width: 121.25px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    color: @gray-4;

    &-active {
      background: @primary-light-4;
      color: @primary;

      svg path {
        fill: @primary;
      }
    }

    svg {
      margin-right: 10px;

      path {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    &:focus,
    &:hover {
      background: @primary-light-4;

      svg path {
        fill: @primary;
      }
    }
  }

  &__filter-count {
    margin-left: 5px;
    background: @primary;
    color: #fff;
    padding: 2px 6px;
    border-radius: 50%;
    font-size: 10px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';