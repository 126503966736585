@import 'assets/less/variables';

.manage-entity-buttons {
  &__outter-wrapper {
    position: relative;
    width: 100%;
  }

  &__edit-btn {
    position: absolute;
    right: 0;
    top: 0;

    .screen-sm({
      right: 0;
    });
  }

  &__content-wrapper:hover + .manage-entity-buttons__wrapper {
    visibility: unset;
    transition: 0.2s;
    opacity: 1;
  }

  &__wrapper {
    visibility: hidden;
    transition: 1s;
    opacity: 0;
  }

  &__wrapper:hover {
    visibility: unset;
    transition: 0.2s;
    opacity: 1;
  }

  &__wrapper-visible {
    visibility: unset;
    opacity: 1;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';