@import 'assets/less/variables';
@import 'assets/less/colors';

.inbox-thread {
  display: flex;
  flex: 1;

  &__wrapper {
    flex: 1 1;
    max-height: 100dvh;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 72px;
    padding: 16px;
    background-color: @white;
    border-bottom: 1px solid @grey-300;

    .screen-lg({
      gap: 12px;
    });

    &__details {
      display: flex;
      flex-flow: column;
      flex: 1 0 auto;
      gap: 2px;

      &__name {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;

        .screen-lg({
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        });
      }

      &__activity {
        color: @grey-600;
        font-size: 10px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.1px;
      }
    }
  }

  &__details-drawer {
    &__content {
      height: calc(100dvh - 72px);
      border-left: unset !important;
    }

    .ant-drawer-content {
      border-radius: 12px 12px 0px 0px;
    }

    .ant-drawer-body {
      padding: unset;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';