@import 'assets/less/variables';

.process-block {
  display: flex;
  flex-flow: column;
  margin-bottom: 83px;

  .screen-lg({
    margin-bottom: 56px;
  });

  h2 {
    text-align: center;
    margin-bottom: 54px;

    .screen-md({
      margin-bottom: 32px;
    });
  }

  &__content {
    display: flex;
    margin: 0 98px;

    .screen-lg({
      flex-direction: column;
      margin: 0
    });
  }

  &__left {
    min-width: 417px;
    margin-right: 82px;

    .screen-lg({
      min-width: unset;
      margin: 0 auto 32px auto;
    });
  }

  &__item {
    position: relative;
    width: 100%;
    margin-bottom: 12px;
    padding: 16px 32px 16px 20px;
    text-align: left;
    border: 1px solid @gray-6;
    border-radius: @border-radius;
    background: #fff;
    opacity: 0.8;
    box-shadow: 0 2px 10px rgba(41, 41, 42, 0.05);
    transition: box-shadow 0.2s, opacity 0.2s;
    cursor: pointer;

    &--active {
      opacity: 1;
      box-shadow: 0 3px 18px rgba(41, 41, 42, 0.11);

      &::after {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 4px;
        height: calc(100% - 16px);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background: @primary;
      }
    }

    &--opacity {
      opacity: 0.6;
    }
  }

  &__button {
    width: unset;
    height: unset;
    outline: none;
    border: none;
  }

  svg {
    margin: 0 auto;
    width: 483px;
    height: 425px;

    .screen-lg({
      width: 326px;
      height: 286px;
    });
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';