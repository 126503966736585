@import 'assets/less/variables';

.image-carousel {
  position: relative;
  background-color: #091828;
  border-radius:  @border-radius-5;
  overflow: hidden;
  z-index: 1;

  &__image-wrapper {
    height: 444px;

    .screen-lg({
      height: 187px;
    });

    img {
      margin: 0 auto;
      max-width: 100%;
      height: auto;

      .screen-lg({
        height: 187px;
      });
    }
  }

  .slick-dots-bottom {
    margin-bottom: 0;
    bottom: 28px;
  }

  .slick-dots li {
    width: 8px !important;

    button {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: @gray-2;
      opacity: unset;
    }
  }

  .slick-dots li.slick-active button {
    background-color: @gray-1;
  }

  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
    z-index: 2;

    .screen-lg({
      width: 24px;
      height: 24px;
    });
  }
  .slick-prev {
    left: 24px;

    .screen-lg({
      left: 16px;
    });
  }
  .slick-next {
    right: 24px;

    .screen-lg({
      right: 32px;
    });
  }

  &__image-count {
    position: absolute;
    bottom: 24px;
    left: 24px;
    border-radius: @border-radius-3;
    background-color: #fff;
    padding: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: @dark;
    z-index: 1;

    .screen-lg({
      bottom: 16px;
      left: 16px;
      padding: 4px;
      font-weight: 400;
      font-size: 12px;
      line-height: 19px;
    });
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';