@import 'assets/less/variables';

.salary-estimator {
  width: 100%;
  max-width: 1188px;
  margin: 113px auto 88px auto;
  padding: 0 24px;

  .screen-md({
    margin-top: 32px;
  });

  h1 {
    font-family: @font-family-secondary;
    font-weight: 700;
    font-size: 28px;
    line-height: 44px;
    color: @dark;
    text-align: center;

    .screen-lg({
      font-size: 20px;
      line-height: 28px;
    });
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: @gray-1;

    .screen-lg({
      font-size: 14px;
      line-height: 22px;
    });
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';