@import 'assets/less/variables';

.provider-logo {
  &__default {
    width: 40px;
    height: 40px;
    background: #e0f0ff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }
}

.providers-tab {
  width: 100%;

  &__column-text {
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    color: @dark-2;
  }

  &__column-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: @gray-8;
  }
}

.provider-type {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__icon {
    path {
      fill: #091828;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';