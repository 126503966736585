@import 'assets/less/variables';

.custom-date-range-picker {
  width: 100%;

  &__wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;

    .screen-sm({
      width: 100%;
      flex-direction: column;
    });
  }

  &__end-date {
    width: 100%;
    padding-left: 8px;

    .screen-sm({
      padding: 0;
    });
  }
  &__start-date {
    width: 100%;
    padding-right: 8px;

    .screen-sm({
      padding: 0;
    });

    .ant-form-item {
      margin-bottom: 24px !important;
    }
  }

  &__label {
    display: flex;
    flex-direction: row;

    &-text {
      width: 100%;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';