@import 'assets/less/variables';
@import 'assets/less/colors';

.specialities-group {
  display: flex;
  gap: 16px;

  &__specialities-modal {
    &__header {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 8px;

      &__text {
        color: @dark;
        font-size: 18px;
        font-weight: 700;
        line-height: 156%;
      }
    }

    &__specialities {
      margin-bottom: 10px;
    }

    &__controls {
      display: flex;
      justify-content: center;

      &__action-btn {
        width: 434px;
      }
    }

    .modal-sm .ant-modal-close {
      top: 6px;
      right: -2px;
    }

    .ant-modal-body {
      padding: 12px 16px 16px;
    }

    .ant-modal-title {
      font-weight: 700;
    }
  }

  &__group-specialities {
    padding: 12px 8px;
    flex-wrap: wrap;
    border-radius: 0px 0px 4px 4px;
    border: 1px solid @blue-200;
    background: @blue-100;
    width: 100%;

    &__badge {
      display: flex;
      padding: 4px 8px;
      align-items: center;
      gap: 6px;
      border-radius: 48px;
      background: @primary;

      &__text {
        color: @light;
        font-size: 16px;
        font-weight: 600;
        line-height: 148%;
      }
    }
  }

  &__icon {
    flex-shrink: 0;
    margin-right: 10px;
    width: 32px;
    height: 32px;

    .screen-sm({
      margin-right: 8px;
      width: 24px;
      height: 24px;
    });

    & path {
      fill: @gray-4;
      transition: fill 0.3s;
    }
  }

  &__action {
    display: flex;
    gap: 8px;

    &__icon {
      width: 16px;
      height: 16px;
    }

    &__text {
      color: @primary;
      font-size: 14px;
      font-weight: 600;
      line-height: 100%;
      text-decoration-line: underline;
    }
  }

  & :nth-of-type(4) &__icon {
    margin-right: 8px;
  }

  &__btn {
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 40px;
    padding: 14px 16px;
    line-height: 1.2;
    color: @gray-3;
    user-select: none;
    border: 1px solid @grey-300;
    border-radius: 4px;

    .screen-sm({
      padding: 12px 16px;
    });

    &.ant-checkbox-wrapper {
      align-items: center;
    }

    &--borderless {
      border: none;
      padding: 0;

      .specialities-group__name {
        font-size: 14px;
        line-height: 24px;
      }

      &.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
        .specialities-group__icon path {
          fill: @dark;
        }
        .specialities-group__name {
          color: @dark;
        }
      }
    }

    &.ant-checkbox-wrapper > span:nth-child(2) {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &.ant-checkbox-wrapper-checked {
      background-color: @blue-100;
      border-color: @blue-600 !important;
      box-shadow: 0 0 15px @blue-100 !important;

      .specialities-group__icon path {
        fill: @primary;
      }
      .specialities-group__name {
        color: @primary;
      }
    }

    &:hover {
      border-color: @primary;
    }
  }

  &--vertical {
    flex-direction: column;

    & .radio-group__item:not(:last-child) {
      margin: 0 0 8px 0;
    }

    & .radio-group__btn {
      width: 100%;
    }
  }

  &__name {
    font-size: 18px;
    font-weight: 600;
    line-height: 156%;
    width: 100%;
    white-space: break-spaces;
    color: @gray-4;

    .screen-sm({
      font-size: 16px;
      line-height: 148%;
    });
  }

  &__after-btn:not(:empty) {
    > .ant-form-item:last-child {
      margin-bottom: 0px;
    }
  }

  &__item:last-child > &__after-btn {
    margin-bottom: 0px;
  }

  .ant-checkbox {
    margin-bottom: 5px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';