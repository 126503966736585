@import 'assets/less/variables';

.add-hp-user-facility {
  &__column {
    display: inline-block;
    vertical-align: top;
    width: 444px;
    padding-left: 72px;

    .editor {
      min-height: 200px;
    }
  }
  &__column:first-child {
    padding-left: unset;
    padding-right: 72px;
    border-right: 4px solid @gray-6;
  }

  &__default-image {
    max-width: 100%;
    cursor: pointer;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      width: 170px;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';