@import 'assets/less/variables';

.vacancy-content {
  &__column {
    width: calc(50% - 8px);
    display: inline-block;
    vertical-align: top;

    .screen-lg({
      width: 100%;
      display: block;
    });

    > div {
      margin-top: 16px;
    }

    &:first-child {
      margin-right: 16px;

      .screen-lg({
        margin-right: 0;
      });
    }
  }

  &__agency {
    border: 1px solid @gray-2;
    border-radius: 16px;
    background-color: @light;
    width: unset;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';