@import 'assets/less/variables';

.inactive-candidate-modal {
  .ant-modal-footer {
    gap: 8px;
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    gap: 24px;

    &__banner {
      display: flex;
      justify-content: center;

      &__image {
        width: 286px;
        height: 237px;
      }
    }

    &__info {
      display: flex;
      flex-flow: column nowrap;
      gap: 8px;

      &__title {
        color: @dark;
        font-size: 18px;
        font-weight: 600;
        line-height: 156%;
        margin-bottom: 0;
      }

      &__description {
        color: @gray-1;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';