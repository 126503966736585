@import 'assets/less/variables';

.team {
  margin-bottom: 56px;

  &__container {
    display: flex;
    justify-content: center;
  }

  &__subtitle {
    max-width: 626px;
    padding-bottom: 32px;
  }

  &__title {
    max-width: 700px;
    margin: 0 auto;

    .screen-sm({
      font-weight: 700;
      font-size: 20px;
    });
  }

  &__image {
    position: relative;
    width: 100%;
    margin-bottom: 16px;
    padding-top: calc(356 / 267 * 100%);
    overflow: hidden;
    border-radius: @border-radius-4;
    background: @primary-light;

    .screen-md({
      padding-top: 100%;
    });
  }

  &__photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .screen-md({
      height: auto;
    });
  }

  &__name {
    margin-bottom: 5px;
    font-weight: 600;
  }

  &__info {
    font-size: 14px;
  }

  .screen-md({
    margin-bottom: 88px
  });
}

.image-cover {
  object-fit: cover;
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';