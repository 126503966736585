/* Set html/body/root/app height */
html,
body,
#root,
.App {
  height: 100%;
  min-height: 100dvh;
}
html {
  scroll-behavior: smooth;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}
.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 14px;
}
.ant-form-item-has-success:not(.form-item-wrapper) .ant-form-item-control-input-content > input:not(:focus),
.ant-form-item-has-success:not(.form-item-wrapper) .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused),
.ant-form-item-has-success:not(.form-item-wrapper) .ant-input-number-wrapper > .ant-input-number:not(.ant-input-number-focused),
.ant-form-item-has-success:not(.form-item-wrapper) .ant-input-number-affix-wrapper-status-success:not(
      .ant-input-number-affix-wrapper-focused
    ),
.ant-form-item-has-success:not(.form-item-wrapper) .ant-select .ant-select-selector {
  border: 1px solid #22b02e;
}
.ant-form-item-has-error .ant-checkbox-inner,
.ant-form-item-has-error .ant-radio-inner {
  border: 1px solid #ff4d4f;
}
.ant-form-item-has-feedback .ant-input {
  padding-right: 30px;
}
.word-break-style {
  word-break: break-word;
}
.ant-radio-button-wrapper:first-child {
  border-radius: 4px;
}
.ant-checkbox ~ span {
  user-select: none;
}
.common-table .ant-table-row:nth-child(even) {
  background: #f7fbff;
  border-bottom: 1px solid #cfd4dd;
}
.ant-popover-inner {
  border-radius: 12px;
  border: 1px solid #daecff;
  box-shadow: 2px 8px 12px 0px rgba(42, 52, 62, 0.1);
}
.hp-page-header {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
}
.hp-page-header.ant-page-header.has-breadcrumb {
  padding-top: 24px;
}
.hp-page-header.ant-page-header.has-breadcrumb .ant-page-header-heading-left {
  margin: 10px 0 0 0;
}
.hp-page-header.ant-page-header.has-breadcrumb .ant-page-header-heading-title {
  font-size: 23px;
  line-height: 23px;
}
