@import 'assets/less/variables';

.hp-user-vacancy-details {
  height: calc(100% - 115px);
  position: relative;
  margin-bottom: 50px;

  .ant-tabs-nav {
    background: #fff;
    padding-left: 24px;
    margin: 0;
  }

  .ant-tabs-tab-btn {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: @gray-4;
  }

  &__section-header {
    font-size: 16px;
    font-weight: 600;
    color: @gray-4;
    margin: 24px 24px 0 24px;
  }

  &__actions-panel-wrapper {
    margin: 24px 24px 0 24px;
  }

  &__details {
    display: flex;
    flex-direction: row;
  }

  &__column-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-bottom: 0;
  }

  &__editable-details {
    max-width: 673px;
    width: 100%;
  }

  &__otter-wrapper {
    .ant-tabs-content-top {
      height: 100%;
    }

    .ant-tabs-top {
      height: 100%;
    }
  }
}

.vacancy-details-wrapper {
  max-width: 674px;
  margin: 24px;
  padding: 24px;
  height: min-content;
  background: #ffffff;
  border: 1px solid #f4f5f7;
  box-sizing: border-box;
  box-shadow: @box-shadow-4;
  border-radius: @border-radius-5;
  color: @gray-4;
}

.vacancy-facility {
  margin-left: 0;
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';