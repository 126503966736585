@import 'assets/less/variables';

.editor {
  border: 1px solid @gray-2;
  border-radius: 2px;
  padding: 12px;
  font-size: 14px;
  color: @gray-4;

  &__toolbar {
    display: flex;
    padding-bottom: 12px;
  }

  &__body {
    overflow: auto;
    max-height: 150px;
  }

  .ant-divider-vertical {
    height: 25px;
    border-left: 1px solid @gray-2;
  }

  .slate-ToolbarButton {
    margin-right: 5px;
  }

  .slate-ToolbarButton-active {
    background: @primary-light-4;
    border-radius: 4px;
    svg {
      path {
        fill: @primary;
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';