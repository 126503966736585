@import 'assets/less/variables';

.preferences {
  display: flex;
  flex-flow: column;
  gap: 16px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid @primary-light-2;
  background: @light;

  &__toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      display: flex;
      gap: 8px;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 156%;
    }

    &__actions {
      display: flex;
      gap: 20px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    gap: 8px;

    &__button {
      color: @gray-4;
      background-color: @gray-6;
      border-radius: 8px;
      padding: 10px 16px;

      &--active, &--active:focus {
        background: @primary;
        color: #fff;
      }

      &__text {
        font-size: 12px;
        line-height: 20px;
        text-transform: uppercase;
      }
    }

    &__button--add-new {
      height: 40px;
      padding: 10px 16px;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';