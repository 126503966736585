@import 'assets/less/variables';

.talent-account-settings {
  &__wrapper {
    width: 100%;
    max-width: 1188px;
    padding: 0 0 16px 0;
    margin: 0 auto;

    .screen-sm({
      padding: 0 16px 16px 16px;
    });
  }

  &__profile-card {
    max-width: unset;
    margin: unset;
  }

  &__content {
    margin: 16px;
    max-width: unset;
    margin: unset;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 445px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';