@import 'assets/less/variables';

.candidates-overview {
  &__filter {
    .ant-form-item {
      min-width: 208px;
      margin-bottom: 0;
    }
  }

  &__wrapper {
    border-radius: @border-radius-2;
    border: 1px solid @primary-light-2;
    background-color: #fff;
    margin: 16px 24px;
  }

  &__status-filter {
    padding: 24px;
  }

  &__filter-button {
    border: none;
    border-radius: @border-radius-2;
    background: @gray-6;
    padding: 12px;
    font-size: 12px;
    color: @gray-4;
    margin: 0 8px 8px 0;
  }

  &__button-span {
    margin-left: 8px;
    padding: 0 6px;
    border-radius: @border-radius-2;
    background: #e9ebef;
    color: @gray-4;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &__filter-button:hover {
    .candidates-overview__button-span {
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
    }
  }

  &__active {
    background: @primary;
    color: #fff;
  }

  &__button-span-active {
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
  }

  &__filter-dropdown-button {
    width: unset;
    height: unset;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border: none;
    background: transparent !important;

    svg {
      path {
        fill: @gray-1;
      }
    }
  }

  &__menu-item {
    margin-left: 23px;
  }

  &__menu-item-active {
    margin-left: 8px;
  }

  &__table {

    &__column {
      &__name {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .ant-table-thead {
      .ant-table-cell {
        background: #fff;
        font-size: 12px;
        font-weight: 700;
        color: @gray-1;
      }
      .ant-table-cell::before {
        display: none;
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        height: 48px;
        font-size: 14px;
      }
    }

    .ant-table-pagination {
      margin-right: 24px !important;
    }

    .ant-tag {
      border: none;
      display: inline-flex;
      align-items: center;

      &.ant-dropdown-trigger {
        cursor: pointer;
      }
    }
  }

  &__menu-button {
    width: unset;
    height: unset;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent !important;

    svg {
      path {
        fill: @gray-1;
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';