@import 'assets/less/variables';

.facility-details {
  padding: 24px;

  &__info-wrapper {
    margin-bottom: 24px;
    padding: 24px;
    word-break: break-word;
    background-color: @light;
    border: @border-style-1;
    border-radius: @border-radius-5;
    position: relative;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__edit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    top: 20px;
    right: 20px;
    margin: 0;
  }

  &__title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: @dark;
  }

  &__status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 94px;
    height: 24px;
    margin: 4px 0 8px 0;

    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: @primary;
    background: @primary-light-4;
    border-radius: @border-radius-3;
  }

  &__details {
    display: flex;
    flex-direction: row;
  }

  &__add-info-wrapper {
    width: 100%;
    max-width: 420px;
  }

  &__facility-info {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: @gray-1;
    margin-bottom: 8px;
  }

  &__facility-info:last-child {
    margin-bottom: 0;
  }

  &__icon {
    margin-right: 8px;
    height: 18px;
    width: 18px;

    path {
      fill: @gray-1;
    }
  }

  &__url {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: @gray-4;

    svg {
      fill: @primary;
      width: 24px;
      vertical-align: bottom;
      margin-left: 4px;
    }
  }

  &__url:last-child {
    margin-bottom: 0;
  }

  &__divider {
    min-height: 72px;
    margin: auto 72px;
    width: 1px;
    background-color: @gray-6;
  }

  &__facility-description {
    padding-top: 20px;
  }

  &__subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: @gray-4;
  }

  &__info-block {
    background: #fff;
    padding: 24px;
    min-height: 450px;
    border-radius: 16px;
    border: 1px solid rgba(207, 212, 221, 0.5);
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }

    h2 {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .ant-tabs-tab-btn {
    font-size: 14px;
    font-weight: bold;
  }

  .ant-table {
    font-size: 14px;
    .ant-btn-link {
      height: auto;
      min-height: auto;
      padding: 0;
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      font-weight: bold;
    }
  }

  &__info-block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-search {
      width: 100px;
    }
  }

  &__create-team-member {
    margin-left: 16px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';