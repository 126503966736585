@import 'assets/less/variables';

.work-experience {
  &__outter-wrapper {
    margin-bottom: 16px;
  }

  &__outter-wrapper:nth-last-child(1) {
    margin-bottom: 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }

  &__courses-info {
    word-break: break-word;
  }

  &__icon {
    width: 32px;
    height: 32px;
  }

  &__info {
    font-size: 12px;
    line-height: 20px;
    word-break: break-word;
    color: @gray-1;
    margin: 0 64px 0 8px;

    .rich-text {
      font-size: 12px;
      color: @gray-1;
    }

    .screen-sm({
      margin-right: 34px;
    });
  }

  &__work-info {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: @dark;
  }

  &__employer-info {
    font-size: 14px;
    line-height: 24px;
    color: @dark;
  }

  &__description {
    font-size: 12px;
    line-height: 20px;
    white-space: pre-wrap;
    word-break: break-word;
    color: @gray-1;
    font-family: @font-family-primary;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';