@import 'assets/less/variables';

.charity-partners {
  width: 100%;
  max-width: 1188px;
  margin: 0 auto 96px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  gap: 48px;

  .screen-md({
    gap: 16px;
    margin-bottom: 88px;
  });

  &__container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 48px;
    border-radius: 16px;
    background: @light;
    padding: 32px 24px;
    box-shadow: 0px 10px 20px 0px rgba(41, 41, 42, 0.07);

    .screen-md({
      flex-flow: row wrap;
      justify-content: center;
      gap: 24px;
  });
  }

  &__title {
    margin-bottom: 0;
    color: @dark;
    font-size: 28px;
    font-weight: 700;
    line-height: 44px;

    .screen-md({
      font-size: 21px;
    });
  }

  &__image {
    flex-basis: 300px;
    height: 230px;
  }

  &__info {
    display: flex;
    flex-flow: column nowrap;
    gap: 24px;

    .screen-md({
      align-items: center;
    });

    &__description {
      color: @gray-4;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 0;

      .screen-md({
         text-align: center;
      });
    }

    &__action-btn {
      width: 190px;
      margin-right: 8px;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';