@import 'assets/less/variables';

.accelerate-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 104px;

  &__left {
    position: relative;
    z-index: 1;
    max-width: 600px;
    margin-top: 95px;

    .screen-md({
      max-width: none;
      margin: 32px 0 40px 0;
    });
  }

  &__title {
    font-family: @font-family-secondary;
    font-size: 32px;
    font-weight: 800;
    line-height: 48px;
    margin-bottom: 8px;

    .screen-sm({
      font-size: 26px;
      line-height: 40px;
    });
  }

  &__text {
    margin-bottom: 32px;
    color: @gray-4;

    .screen-sm({
      font-size: 14px;
    });
  }

  &__icons {
    width: 392px;
    display: flex;
    flex-wrap: wrap;

    .screen-sm({
      width: 100%;
      flex-direction: column;
      align-items: center
    });

    svg {
      width: 172px;
      margin: 0 24px 24px 0;

      &:nth-child(2) {
        margin: 0 0 24px 0;
      }

      .screen-sm({
         margin: 0 0 24px 0;
      });
    }
  }

  &__right {
    position: relative;
    flex-shrink: 0;
    margin-top: 32px;

    &::before {
      content: '';
      position: absolute;
      top: -22px;
      left: -75px;
      z-index: 0;
      width: 435px;
      height: 435px;
      border-radius: 50%;
      background: @primary-light-4;

      .screen-sm({
        left: -40px;
        top: -36px;
        width: 360px;
        height: 360px;
      });
    }

    &::after {
      content: '';
      position: absolute;
      right: -24px;
      bottom: 180px;
      z-index: 0;
      width: 86px;
      height: 86px;
      border-radius: 50%;
      background: @primary-light-4;

      .screen-sm({
        right: -19px;
        bottom: 290px;
        width: 76px;
        height: 76px;
      });
    }
  }

  &__card {
    position: relative;
    z-index: 1;
    max-width: 424px;
    margin-left: 10px;
    padding: 24px;
    border-radius: @border-radius-4;
    background: @light;
    box-shadow: 0 2px 10px rgba(9, 24, 40, 0.12);

    .screen-md({
      max-width: none;
      margin: 0;
    });

    .screen-sm({
      padding: 24px 16px;
    });
  }

  &__card-title {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 600;
  }

  .screen-md({
    flex-direction: column;
    margin-bottom: 64px;
  });
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';