@import 'assets/less/variables';

.motivation {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: @dark;
  }

  &__record {
    display: flex;
    width: fit-content;
    margin-right: 8px;
    padding: 4px 8px;
    line-height: 24px;
    font-size: 14px;
    line-height: 24px;
    color: @gray-1;
    background: @gray-6;
    border-radius: @border-radius-3;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  &__record-inner {
    display: inline-block;
    padding-right: 8px;
    word-break: break-word;
  }

  &__status {
    padding-left: 6px;
    font-weight: 700;
    word-break: break-word;
    white-space: break-spaces;
  }

  &__icon {
    margin: 3px 8px 0 0;
    min-width: 13px;
  }

  &__check-icon {
    align-items: center;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';