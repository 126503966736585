@import 'assets/less/variables';

.referrals-policy {
  &__title {
    .screen-sm({
      font-size: 18px;
      margin-bottom: 4px;
    });
  }

  &__subtitle {
    margin-top: 8px;
    font-weight: 600;
    .screen-sm({
      font-size: 12px
    });
  }

  &__text {
    color: @gray-1;
    .screen-sm({
      font-size: 12px;
      margin-bottom: 4px;
    });
  }

  &__text-wrapper {
    margin-top: 16px;
  }

  &__text-bold {
    font-weight: 600;
  }
  &__clause-position {
    font-weight: 600;
  }

  & ol {
    list-style: none;
    counter-reset: li;
    padding-inline-start: 0;
    margin-bottom: 16px;
  }

  & ol > li:before {
    margin-right: 8px;
    font-weight: 700;
    counter-increment: li;
    content: counters(li, '.') '. ';
  }

  & ul {
    margin-bottom: 16px;
  }

  & ul > li {
    list-style: disc;
  }

  & li {
    margin-top: 16px;
    .screen-sm({
      font-size: 12px
    });
  }

  &__table {
    &--referral-bonus-structure {
      width: 50%;

      .screen-sm({
        width: 100%;
      });
    }

    &--reward-tiers {
      width: 75%;

      .screen-sm({
        width: 100%;
      });
    }

    word-break: break-word;
    margin-bottom: 16px;
    width: 100%;
    table-layout: fixed;

    & td {
      width: 100%;
      padding: 8px;
      vertical-align: top;
      text-align: left;
      border: 2px solid @gray-2;
    }

    & thead td {
      vertical-align: middle;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';