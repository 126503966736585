@import 'assets/less/variables';

.custom-tooltip {
  &__wrapper {
    display: flex;
    position: relative;
  }

  &__centred {
    position: relative;
    top: 3px;
    left: 3px;
  }
}

.ant-tooltip-inner {
  position: relative;
  top: -16px;
  left: 16px;
  background: @dark;
  opacity: 0.8;
  border-radius: 2px;
}

.ant-tooltip-arrow-content {
  display: none;
  top: 10px;
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';