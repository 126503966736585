@import 'assets/less/variables';

.support-salary-research {
  width: 355px;
  height: fit-content;
  border-radius: @border-radius-5;
  border: 1px solid #e7e9ee;
  background: #ffffff;
  padding: 24px;

  .screen-lg({
    width: 331px;
    height: 439px;
  });

  h2 {
    text-align: center;
    color: @dark;
    margin-bottom: 0;
  }

  p {
    text-align: center;
    color: @gray-4;
    margin-bottom: 24px;
  }

  img {
    width: 305px;
    height: auto;
    margin-bottom: 24px;

    .screen-lg({
        width: 283px;
        height: auto;
    });
  }

  a {
    display: block;
    padding: 8px 0;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    font-size: 16px;
    color: @primary;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';