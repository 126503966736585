@import 'assets/less/variables';

.talent-salary-estimator {
  background: @bgc-primary;
  padding-bottom: 16px;

  &__wrapper {
    max-width: 1138px;
    margin: 0 auto;

    .screen-lg({
      padding: 0 16px;
    });
  }

  h1 {
    line-height: 44px;
    font-weight: 700;
    font-size: 28px;
    color: #ffffff;
    margin-bottom: 8px;

    .screen-lg({
        text-align: center;
        font-size: 20px;
    });
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    color: @dark;
  }

  h3 {
    line-height: 32px;
    font-weight: 600;
    font-size: 20px;
  }

  p {
    line-height: 24px;
    font-weight: 400;
    font-size: 14px;
  }

  .ant-carousel {
    padding: 0 0 24px 0;
  }

  .ant-carousel .slick-dots {
    margin: 0;
  }

  .ant-carousel .slick-dots li,
  .ant-carousel .slick-dots li button,
  .ant-carousel .slick-dots li.slick-active {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    padding: 0;
    background: #bfdfff;
    margin: 0 4px;
  }

  .ant-carousel .slick-dots li button {
    background: none;
    margin: 0;
  }

  .ant-carousel .slick-dots li.slick-active {
    background: @primary;
  }

  .ant-carousel .slick-dots-bottom {
    bottom: 0px;
  }

  &__make-your-profile-stand-out,
  &__refer-your-colleagues {
    margin-bottom: 34px;
  }

  &__flexed-wrapper {
    display: flex;

    .screen-lg({
      flex-direction: column-reverse;
    });
  }

  &__flexed-right {
    .screen-lg({
      overflow-x: scroll;
      margin-bottom: 16px;
    });
  }

  &__overflow-content {
    .screen-lg({
      width: fit-content;
      background: @bgc-primary;
      display: flex;
      margin-bottom: 8px;
    });
  }

  &__form {
    margin-right: 16px;

    .screen-lg({
      margin-right: unset;
    });
  }

  &__support-salary-research {
    margin-bottom: 16px;

    .screen-lg({
      margin-right: 12px;
      margin-bottom: unset;
    });
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';