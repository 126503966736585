@import 'assets/less/variables';

.featured-entity-header {
  &__background {
    position: absolute;
    top: 22px;
    right: 0;
    width: 479px;
    height: 696px;
    z-index: -1;

    .screen-lg({
      width: 242px;
      height: 351px;
    });
  }
  &__container {
    margin-bottom: 150px;

    .screen-lg({
      margin-bottom: 72px;
    });
  }

  &__primary-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 162px;
    height: 48px;

    svg {
      margin-left: 16px;
      width: 24px;
      height: 24px;
    }
  }

  &__secondary-button {
    height: 48px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';