@import 'assets/less/variables';

.show-vacancy-information {
  &__records-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    & :last-child {
      margin-bottom: 0;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .labeled-content__record:last-child {
      margin-bottom: 14px;
    }
  }

  &__sub-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: @dark;
  }

  &__job-description {
    &__sub-title {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: @gray-1;
    }

    &__description {
      font-size: 14px;
      line-height: 24px;
      color: @gray-4;
    }

    &:not(:last-child) {
      padding-bottom: 16px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: @gray-4;
    white-space: pre-wrap;
    margin-top: 8px;
  }

  &__benefits {
    margin-top: 8px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';