@import 'assets/less/variables';
@import 'assets/less/colors';

.work-experience-form {
  &__date-range-picker {
    flex-direction: column;

    .custom-date-range-picker__start-date {
      padding: 0;
    }

    .custom-date-range-picker__end-date {
      padding: 0;

      .ant-form-item {
        margin-bottom: 10px;
      }
    }
  }

  &__currently-working-form-item {
    .ant-form-item-control-input {
      min-height: unset;
    }

    &__text {
      color: @gray-4;
      font-size: 16px;
      font-weight: 400;
      line-height: 148%;
    }
  }

  &__additional_action {
    margin: 16px auto 0;
    padding: 0;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: @blue-700;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    text-decoration-line: underline;

    svg {
      path {
        fill: @blue-700;
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';