@import 'assets/less/variables';
@import 'assets/less/colors';

.tag-group {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 0;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 32px;
    border: 1px solid @primary;
    cursor: pointer;
    background-color: @light;
    color: @primary;

    &:hover {
      color: @light;
      border-color: @blue-700;
      background: @blue-700;

      svg path {
        fill: @light;
      }
    }

    :last-child > &__after-btn {
      margin-bottom: 0px;
    }

    &--selected {
      background-color: @primary;
      color: @light;

      svg path {
        fill: @light;
      }
    }

    &--disabled {
      background-color: @bgc-primary;
      color: @gray-3;
      border-color: @gray-2;

      svg path {
        fill: @gray-3;
      }
    }

    &--borderless {
      border: none;
      padding: 0;

      .checkbox-group__name {
        font-size: 14px;
        line-height: 24px;
      }

      &.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
        .checkbox-group__icon path {
          fill: @dark;
        }
        .checkbox-group__name {
          color: @dark;
        }
      }
    }

    &__name {
      white-space: break-spaces;
      font-size: 18px;
      font-weight: 600;
      line-height: 156%;
    }

    &__icon {
      flex-shrink: 0;

      & path {
        fill: @primary;
        transition: fill 0.3s;
      }
    }

    &.ant-checkbox-wrapper {
      align-items: center;

      > span:nth-child(2) {
        display: flex;
        align-items: center;
        width: 100%;
      }
    }

    &.ant-checkbox-wrapper-checked {
      .checkbox-group__icon path {
        fill: @primary;
      }
      .checkbox-group__name {
        color: @primary;
      }
    }
  }

  &--vertical {
    flex-direction: column;
    width: 100%;
  }

  &--full-width {
    padding: 0;

    .tag-group__item {
      width: 100%;
      max-width: unset;
    }
  }

  &__after-btn:not(:empty) {
    padding-left: 16px;

    > .ant-form-item:last-child {
      margin-bottom: 0px;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';