@import 'assets/less/variables';

.profile-info {
  &__wrapper {
    width: 100%;
    max-width: 757px;
    min-height: 256px;
    padding: 24px;
    background: @light;
    border: 1px solid @gray-2;
    border-radius: @border-radius-5;

    .screen-sm({
      padding: 16px;
    });
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: @dark;
  }

  &__edit-button {
    padding: 0;
  }

  &__records-wrapper {
    margin-top: 8px;

    & :last-child {
      margin-bottom: 0;
    }
  }

  &__record {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
  }

  &__record-title {
    display: block;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: @gray-1;
  }

  &__record-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: @gray-4;
  }

  &__icon {
    margin-right: 8px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';