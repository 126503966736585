@import 'assets/less/variables';
@import 'assets/less/colors';

.featured-facilities {
  text-align: center;

  &__title-margin {
    margin-bottom: 32px;
  }

  &--outlined {
    .slick-track {
      display: flex;
      gap: 24px;
    }

    .featured-facilities__image {
      align-items: center;
      margin: 0 24px;
      width: 266px;
      height: 140px;
      border-radius: 8px;
      background: @blue-100;
    }
  }

  span {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: @gray-4;
    margin-bottom: 22px;
  }

  .ant-carousel .slick-list .slick-slide > div > div {
    display: flex !important;
    justify-content: center;
  }

  img {
    width: 176px;
    height: 58px;
    object-fit: contain;
  }

  img:nth-child(3) {
    margin-right: 0;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';