@import 'assets/less/variables';
@import 'assets/less/colors';

.postgraduate-qualifications-status-form {
  &__parent-form-item {
    overflow-y: auto;
    max-height: 425px;
  }

  &__row {
    display: flex;
    flex-flow: column;
    padding-bottom: 16px;
    margin-bottom: 16px;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    &--bordered {
      border-bottom: 1px solid @grey-300;
    }

    &__speciality-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: @gray-4;
      font-size: 16px;
      font-weight: 600;
      line-height: 148%;
    }

    &__status {
      margin-top: 16px;
      margin-bottom: 8px !important;
      padding-left: 8px;

      &__radio {
        display: flex;
        flex-flow: column;
        gap: 10px;

        span:not(.ant-radio) {
          line-height: 148%;
        }
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';