@import 'assets/less/variables';

.actions-panel {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #f4f5f7;
  box-shadow: @box-shadow-4;
  border-radius: @border-radius-5;
  color: @gray-4;

  &__form {
    .ant-form-item {
      margin-bottom: unset;
    }
  }

  &__select {
    width: 358px !important;
  }

  &__option {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    p {
      margin: 0;
    }
  }

  &__assignee-vacancies {
    color: @gray-1;
  }

  &__publish-vacancy-popover {
    cursor: pointer;

    &__content {
      color: @dark;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 156%;

      p:last-child {
        margin-bottom: 0;
      }
    }

    .ant-btn {
      width: 100%;
    }
  }

  &__buttons-wrapper {
    min-height: 100%;
    display: flex;
    align-items: flex-end;
  }

  &__secondary-button {
    width: 140px;
    display: flex;
    align-items: center;
    color: @gray-1;
    svg {
      margin-right: 10px;
      path {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
    &:focus,
    &:hover {
      color: @primary;
      svg {
        path {
          fill: @primary;
        }
      }
    }
  }

  &__primary-button {
    width: 200px;
    border: none;

    > button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
    }

    &__icon {
      width: 20px;
      height: 20px;

      path {
        fill: @gray-9;
      }
    }
  }

  &__alt-primary-button {
    width: 200px;
  }

  .statusIcon() {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    margin-bottom: 8px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';