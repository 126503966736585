@import 'assets/less/variables';

.make-your-profile-stand-out {
  border-radius: @border-radius-5;
  position: relative;
  height: 328px;
  background-image: url('~assets/images/make-your-profile-stand-out-bg.svg');
  padding: 78px 48px 66px 48px;
  overflow: hidden;

  .screen-lg({
    height: 446px;
    background-image: url('~assets/images/make-your-profile-stand-out-bg-mobile.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 16px;
  });

  p {
    width: 463px;
    color: #ffffff;
    margin-bottom: 44px;

    .screen-lg({
        width: unset;
        max-width: 275px;
        text-align: center;
        margin: 0 auto 20px auto;
    });
  }

  div {
    .screen-lg({
        width: 279px;
        margin: 0 auto;
    });
  }

  &__primary-button {
    width: 196px !important;

    .screen-lg({
        display: block;
        width: 279px !important;
    });
  }

  &__link {
    padding: 10px 32px;
    line-height: 24px;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;

    .screen-lg({
        display: block;
        padding: 10px 73px;
    });
  }

  img {
    position: absolute;
    top: 38.3px;
    right: 26px;
    width: 388px;
    height: auto;

    .screen-lg({
        width: 260px;
        top: 297px;
        right: calc(50% - 130px);
    });
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';