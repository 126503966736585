@import 'assets/less/variables';

.about-us {
  padding-bottom: 96px;
  overflow: hidden;

  &__background {
    padding-top: 86px;
    background: @primary-light;

    .screen-md({
      margin-top: 0
    });
  }

  &__info {
    max-width: 536px;

    .screen-md({
      max-width: none;
      margin: 24px 0;
    });
  }

  &__title {
    text-align: left;
    margin-bottom: 16px;

    .screen-sm({
      font-size: 21px;
    });
  }

  &__text {
    margin: 0 0 32px 0;
    color: @gray-4;

    .screen-sm({
      font-size: 14px;
    });
  }

  &__image {
    position: relative;
    width: 100%;
    vertical-align: top;
    overflow: hidden;
    border-radius: @border-radius-4;
    background: @primary-light;
    margin: 0 0 24px 0;
  }

  &__additional-background {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      width: 200%;
      height: 55px;
      background: @primary-light;
    }
  }

  &__photo {
    width: 100%;
    height: 100%;
  }

  .screen-md({
    padding-bottom: 56px;
  });
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';