@import 'assets/less/variables';

.recommended-tab {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 16px;

  &__alert {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-radius: 24px;
    border: 1px solid @primary-light-2;
    background: #f6fbff;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;

    .ant-alert-icon {
      margin-right: 20px;
    }

    .ant-alert-message {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';