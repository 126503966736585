@import 'assets/less/variables';

.vacancy-detail {
  &__column svg path {
    fill: @gray-4;
  }

  &__status {
    width: fit-content;
    height: 26px;
    padding: 1px 8px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: @gray-1;
    background: @gray-6;
    border-radius: 4px;
  }

  &__facility {
    width: 400px;
    border: 1px solid rgba(207, 212, 221, 0.5);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 24px;
  }

  &__facility-title {
    display: flex;

    img {
      width: 32px;
      height: 32px;
      margin-right: 8px;
      border-radius: 4px;
    }
    h3 {
      font-weight: 600;
      font-size: 16px;
      margin: 0;
    }

    p {
      font-weight: 600;
      font-size: 12px;
      color: @gray-1;
    }
  }

  &__facility-name {
    text-transform: uppercase;
    font-size: 12px;
    color: @gray-1;
    font-weight: 600;
    margin: 0;
  }

  &__organization {
    h2 {
      font-weight: 600;
      font-size: 16px;
      margin: 0 0 5px 0;
      color: @gray-4;
    }

    a {
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #42526e;
  }

  &__last_title {
    margin-top: 16px;
  }
}

.vacancy-main-info {
  &__header {
    background-color: #fff;
    border: 1px solid rgba(207, 212, 221, 0.5);
    box-sizing: border-box;
    border-radius: 16px;
    padding: 24px;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }

    .ant-tag {
      border: none;
      padding: 0;
      margin-top: 12px;
      background: #fff;
      font-weight: 600;
      font-size: 14px;
      white-space: initial;
      display: inline-flex;
      align-items: center;
      color: @gray-4;

      svg {
        margin-right: 5px;
        min-width: 24px;
      }
    }
  }

  &__tag-wrapper {
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-bottom: 12px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';