@import 'assets/less/variables';

.nurse-advocates {
  border-radius: @border-radius-5;
  width: 350px;
  height: fit-content;
  background: #ffffff;
  overflow: hidden;

  .screen-lg({
    width: 331px;
    height: 439px;
  });

  img {
    width: 350px;
    height: auto;

    .screen-lg({
        width: 331px;
    });
  }

  div {
    height: 246px;
    border-bottom-left-radius: @border-radius-5;
    border-bottom-right-radius: @border-radius-5;
    border: 1px solid #e7e9ee;
    padding: 24px 24px 32px 24px;
  }

  h2 {
    text-align: center;
    margin-bottom: 0;
  }

  p {
    text-align: center;
    color: @gray-4;
    margin-bottom: 36px;
  }

  a {
    display: block;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    font-size: 16px;
    color: @primary;
    margin-bottom: 16px;
  }

  a:last-child {
    margin-bottom: 0;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';