@import 'assets/less/variables';

.sign-in {
  &__card-content {
    padding: 24px;
  }

  &__card {
    .screen-sm({
      padding: 24px;
      min-height: 0;
    });
  }

  &__icon {
    width: 100%;
    max-width: 327px;
    height: 100%;
    margin-bottom: 24px;

    .screen-sm({
      max-width: 176px;
    });
  }

  &__title {
    margin-bottom: 8px;
    margin-top: 32px;
    line-height: 36px;

    .screen-sm({
      margin-top: 0;
      font-size: 24px;
      line-height: 36px;
    });
  }

  &__subtitle {
    max-width: 424px;
    color: @success;
  }

  &__button {
    width: 100%;
    max-width: 224px;
  }

  &__details-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    height: 24px;
    margin: 4px 0 16px 0;
    font-weight: 600;

    .screen-sm({
      font-size: 14px;
    });

    .screen-xs({
      flex-direction: row;
      height: 52px;
    });
  }

  &__remember-me {
    font-size: 14px;

    .screen-sm({
      font-size: 12px;
    });
  }

  &__checkbox-link {
    .screen-sm({
      font-size: 12px;
      font-weight: 600;
    });
  }

  .screen-sm({
    min-height: 400px;
  });

  &__password-requirements {
    font-size: 12px;
    line-height: 20px;
    color: @gray-4;
  }

  &__form {
    padding: 32px;
    border: 1px solid @gray-5;
    border-radius: @border-radius-2;
    margin-top: 16px;
    .screen-sm({
      padding: 16px;
    });
  }

  &__form-element-wrapper {
    margin-bottom: 16px;
  }

  &__resend-button {
    width: 100%;
    margin-top: 8px;
  }

  &__form .ant-input {
    .screen-sm({
      font-size: 14px;
    });
  }

  &__li {
    margin-bottom: 4px;

    &--success {
      color: @success;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    font-weight: 400;
    color: @gray-4;

    &:disabled .auth-form__icon path {
      fill: @gray-3;
    }

    &:first-child {
      margin-right: 10px;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';