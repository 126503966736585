@import 'assets/less/variables';
@import '~react-phone-number-input/style.css';

.change-talent-phone-number {
  &__title {
    margin-bottom: 16px;
    margin-top: 32px;
    font-family: Inter;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }

  &__submit {
    max-width: 236px;
  }

  &.ant-form-item input {
    padding: 6.4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: @border-radius-3;
    transition: border-color 0.3s, box-shadow 0.3s;
    -webkit-appearance: none;

    &::placeholder {
      color: @gray-2;
    }

    &:hover {
      border-color: #63b6ff;
    }

    &:focus {
      border-color: #63b6ff;
      outline: 0;
      box-shadow: 0 0 0 2px rgb(59 156 254 / 20%);
    }
  }

  &.ant-form-item-has-feedback .PhoneInputInput {
    padding-right: 30px;
  }

  &.ant-form-item-has-success .PhoneInputInput {
    border: 1px solid @success;
  }

  &.ant-form-item-has-error .PhoneInputInput {
    border: 1px solid @error;

    &:hover {
      border-color: @error;
    }

    &:focus {
      border-color: #ff7875;
      outline: 0;
      box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
    }
  }

  & .PhoneInputCountrySelectArrow {
    content: '';
    display: block;
    width: 0.3em;
    height: 0.3em;
    margin-left: 0.35em;
    border-style: solid;
    border-color: inherit;
    border-top-width: 0;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 0;
    opacity: 0.45;
    transform: rotate(45deg);
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';