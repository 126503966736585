@import 'assets/less/variables';
@import 'assets/less/colors';

.inbox-thread-details-sidebar {
  flex: 0 0 320px;
  border-left: 1px solid @grey-300;
  background-color: @white;
  display: flex;
  flex-direction: column;

  &:not(&--opened) {
    display: none;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px 16px;

    &__details {
      display: flex;
      flex-flow: column;
      flex: 1 0 auto;
      gap: 4px;

      &__name {
        font-size: 16px;
        font-weight: 600;
        line-height: 148%;
        color: @grey-900;
      }

      &__particulars {
        font-size: 14px;
        line-height: 100%;
        color: @grey-600;
      }
    }

    &__close-button {
      align-self: flex-start;
    }
  }

  &__divider {
    width: auto;
    min-width: unset;
    margin: 8px 16px;
    background-color: @grey-300;
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 16px 12px;

    div {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    span {
      color: @grey-700;
      font-size: 12px;
      line-height: 124%;
    }

    svg {
      height: 16px;
      width: 16px;

      path {
        fill: @grey-900;
      }
    }
  }

  &__tabs {
    flex-grow: 1;

    &__pane {
      height: 100%;
      background-color: @grey-100;
      overflow: auto;
      padding: 10px;
    }

    .ant-tabs-nav {
      padding: 0px 16px;
      margin: 0px;
    }

    .ant-tabs-tab-btn {
      font-size: 12px;
      font-weight: 600;
      line-height: 124%;
      text-transform: uppercase;
    }

    .ant-tabs-content-top {
      height: 100%;
      max-height: calc(100dvh - 262px);

      .screen-lg({
        max-height: calc(100dvh - 254px);
      });
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';