@import 'assets/less/variables';
@import 'assets/less/colors';

.switch {
  display: flex;

  &__button {
    display: flex;
    flex: 1;
    align-items: center;
    height: 38px;
    font-weight: 600;
    font-size: 14px !important;
    user-select: none;

    &:hover {
      color: @blue-800;
      background-color: @blue-100;
    }

    &:first-child {
      border-right: none;
      border-radius: 4px 0 0 4px !important;
    }

    &:not(:first-child) {
      border-left: none;

      &::before {
        display: none;
      }
    }

    &:not(:last-child) {
      border-right: none;
    }

    &--primary {
      color: @blue;
      border-radius: 0px;
      border-color: @grey-200 !important;
      border-width: 2px !important;
    }

    &--secondary {
      padding: 8px;
      min-height: 40px;
      width: 90px;
      border: 1px solid @grey-300 !important;
      background: @white;
      color: @grey-600;
      font-size: 12px !important;
      font-weight: 600;
      line-height: 124%;

      &.switch__button--checked {
        border: 1px solid @blue-700 !important;
      }

      &-disabled {
        &.ant-radio-button-wrapper-disabled {
          background: @bgc-primary;
          color: @grey-400;

          &:first-child {
            background: @bgc-primary;
            color: @grey-400;
          }

          &:hover {
            background: @bgc-primary;
            color: @grey-400;
          }
        }
      }

      &:first-child {
        border-radius: 8px 0px 0px 8px !important;
      }

      &:last-child {
        border-radius: 0px 8px 8px 0px !important;
      }

      &:hover {
        border: 1px solid @blue-500 !important;
      }
    }

    &--checked {
      background-color: @blue-300 !important;

      &:focus-within {
        box-shadow: none !important;
      }

      > span:last-child {
        color: @blue;
      }
    }

    > span:last-child {
      display: flex;
      gap: 4px;
      align-items: center;
      margin: 0 auto;

      > svg path {
        fill: currentColor;
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';