@import 'assets/less/variables';

.vacancy-header {
  border: 1px solid @gray-2;
  border-radius: @border-radius-5;
  overflow: hidden;
  background-color: #fff;

  h1 {
    line-height: 32px;
    font-weight: 600;
    font-size: 24px;
    color: @dark;
    margin-bottom: 8px;

    .screen-lg({
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    });
  }

  h2 {
    line-height: 24px;
    font-weight: 600;
    font-size: 16px;
    color: @gray-1;
    text-transform: uppercase;

    .screen-lg({
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    });
  }

  h3 {
    line-height: 22px;
    font-weight: 600;
    font-size: 18px;
    color: @dark;
    margin-bottom: 8px;

    .screen-lg({
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    });
  }

  p {
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
    color: @gray-4;
    margin-bottom: 0;

    .screen-lg({
      font-size: 12px;
      line-height: 20px;
    });
  }

  &__banner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: @primary;

    .screen-lg({
      img {
        height: 64px;
      }
    });
  }

  &__logo {
    border-radius: @border-radius-5;
    width: 112px;
    height: 112px;
    margin: -56px 0 40px 32px;

    .screen-lg({
      width: 72px;
      height: 72px;
      margin: -48px 0 20px 16px;
    });
  }

  &__details-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    .screen-lg({
      flex-direction: column;
    });

    > div {
      width: 50%;
      padding: 0 32px;

      &:first-child {
        border-right: 2px solid @gray-7;
    
        .screen-lg({
          border-right: none;
          border-bottom: 2px solid @gray-7;
        });
      }

      .screen-lg({
        width: 100%;
        padding: 0 16px;
      });
    }

    ul {
      padding: 0;
      margin: 16px 0;
      list-style-type: none;
  
      li {
        line-height: 24px;
        color: @gray-4;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
  
        &:not(:last-child) {
          margin-bottom: 5px;
        }
  
        .screen-lg({
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
        });
      }
  
      svg {
        margin-right: 5px;
        min-width: 24px;
        height: 20px;
      }
  
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  & &__assignee-name {
    font-weight: 600;
  }

  &__assignee-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .screen-lg({
      margin: 16px 0;
    });

    img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      margin-right: 16px;
    }

    span {
      line-height: 16px;
      font-weight: 500;
      font-size: 12px;
      color: @gray-1;
      margin: 0;
    }
  }

  &__default-profile-picture {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    margin-right: 16px;
    font-size: 36px;
    font-weight: 600;
    color: @primary;
    background-color: @primary-light-4;
  }

  &__status-wrapper {
    margin-top: 16px;
  }

  &__status {
    padding: 8px 0;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .ant-tabs-nav {
    margin: 0 32px !important;
  }
  .ant-tabs-tab {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: @gray-4;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';