@import 'assets/less/variables';

.sign-up-proposal-card {
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  margin-top: 72px;

  .screen-lg({
      margin-top: 30px;
    });

  &__margin {
    margin-top: -204px;

    .screen-lg({
        margin-top: 72px;
      });
  }

  h2 {
    text-align: center;
    font-family: @font-family-secondary;
    font-weight: 700;
    font-size: 28px;
    line-height: 44px;
    color: @dark;

    .screen-lg({
      font-size: 20px;
      line-height: 28px;
    });
  }

  p {
    margin: 0 auto 44px auto;
    width: 772px;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: @gray-1;

    .screen-lg({
      font-size: 14px;
      line-height: 22px;
      width: 100%;
    });
  }

  a {
    display: block;
    margin: 0 auto;
    border-radius: @border-radius-3;
    background-color: @primary;
    padding: 12px;
    width: 143px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';