@import 'assets/less/variables';

.healthcare-providers-user-tab {
  .ant-tabs-nav {
    border-bottom: 1px solid @gray-2;
  }
  .ant-tabs-tab-btn {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: @gray-4;
  }

  .ant-table {
    font-size: 14px;
    color: @dark-2;
  }

  .ant-table-thead {
    .ant-table-cell {
      padding: 10px 16px;
      background: #fff;
      text-transform: uppercase;
      border-bottom: 1px solid @gray-2;
      color: @gray-8;
      font-weight: bold;
    }
  }

  .ant-table-row {
    .ant-table-cell {
      padding: 16px;
    }
    &:nth-child(even) {
      background: @primary-light;
      border-bottom: 1px solid @gray-2;
    }
  }
  &__more-btn {
    display: flex;
    align-items: center;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';