@import 'assets/less/variables';

.upload-cv {
  position: relative;
  background-color: @primary;
  display: flex;
  align-items: center;
  overflow: hidden;

  .screen-sm({
    flex-direction: column;
    justify-content: center;
  });

  img {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: auto;

    .screen-sm({
      display: none;
    });
  }

  &__icon {
    min-width: 96px;
    height: auto;
    margin-right: 32px;

    .screen-sm({
      margin-right: unset;
      margin-bottom: 16px;
    });
  }

  &__centered {
    max-width: 100%;

    .screen-sm({
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    });
  }

  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
    margin-bottom: 8px;

    .screen-lg({
      margin-bottom: 16px;
    });
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    margin-bottom: 16px;

    .screen-sm({
      text-align: center;
    });
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .screen-sm({
      flex-direction: column;
      justify-content: center;
    });

    .ant-btn {
      padding: 6px;
    }
    .ant-btn-primary:hover,
    .ant-btn-primary:focus,
    .ant-btn-primary:active {
      background: none;
    }
    a.ant-btn {
      padding-top: 6px !important;
    }
  }

  &__name {
    width: 260px;
    display: flex;
    align-items: center;

    .screen-sm({
      width: fit-content;
      margin-bottom: 16px;
    });

    svg {
      min-width: 19px;
      height: 20px;
      margin-right: 8px;
    }

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #d1e8ff;
      margin-bottom: 0;
    }
  }

  &__button {
    min-width: 179px;
    height: 40px;
    background-color: #ffffff;
    color: @primary;

    svg {
      margin-left: 18px;
    }
  }

  &__button-wrapper {
    display: flex;
  }

  &__ghost-button {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    color: #ffffff;
    box-shadow: none;

    svg {
      margin-right: 8px;
    }
  }

  &__download-button {
    min-width: 179px;
    height: 40px;
    background: none;
    color: #ffffff;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';