@import 'assets/less/variables';

.admin-tool-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;

  &__title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: @dark;
  }

  &__add-btn {
    margin-left: 16px;
  }

  &__share-btn {
    width: 115px;
  }

  &__crumb {
    margin-bottom: 8px;
    text-align: left;
  }

  &__crumb-item {
    font-size: 14px;
    color: @gray-1;
    font-weight: normal;
    padding: 0;
    height: unset;
  }
}

.search-input {
  background: #ffffff;
  border: 1px solid #dee1e7;
  border-radius: 10px;
  width: 344px;

  &__icon {
    height: 19px;
    width: 19px;
    margin-right: 8px;

    circle,
    line {
      stroke: @gray-4;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';