@import 'assets/less/variables';

.profile-home-page {
  background: @bgc-primary;
  min-height: 100%;

  &__content {
    min-height: calc(100vh - 96px);
    width: 100%;
    max-width: 1188px;
    margin: 0 auto 20px;
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
  }

  h1 {
    margin: 0 0 10px 0;
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 16px;
  }

  &__empty-state {
    padding-top: 12%;
    padding-bottom: 12%;
  }

  &__alert {
    max-width: unset;
    margin: unset;

    .screen-xl({
      margin: 0 24px;
    });

    .screen-lg({
      margin: 0 16px;
    });
  }

  &__tabs {
    padding: 24px 16px;
    background: #ffffff;
    border: @border-style-1;
    box-sizing: border-box;
    border-radius: 16px;
    margin-bottom: 20px;
    height: 100%;

    @media (min-width: 992px) and (max-width: 1199px) {
      margin: 0 24px;
    }

    .screen-lg({
      border: unset;
      padding: 0 16px;
      background: @bgc-primary;
    });
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    min-width: 100%;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs-tab {
    margin: 0 20px 0 0;
    padding: 0 0 16px 0;
  }

  .ant-tabs-tab-btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: @gray-1;

    &:hover {
      color: @primary;
    }
  }

  &__vacancies {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__vacancy {
    margin: 0 16px 16px 0;

    .screen-lg({
      margin: 0 0 16px 0;
    });
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 20px 10px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';