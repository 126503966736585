@import 'assets/less/variables';

.locations {
  position: relative;
  padding-top: 123px;

  .screen-lg({
    padding-top: 32px;
  });

  &__wrapper {
    margin: 0 auto;
    max-width: 1134px;
    padding: 0 32px;
  }

  &__location-titles {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 84px;

    .screen-lg({
      margin-bottom: 72px;
    });

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid @gray-2;
      border-radius: @border-radius-2;
      padding: 0 16px;
      height: 60px;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: @dark;
      margin: 0 24px 24px 0;
      transition: 0.3s;

      &:hover {
        opacity: 0.8;
      }

      .screen-lg({
        width: 100%;
        margin: 0 0 16px 0;
      });
    }
  }

  h2 {
    text-align: center;
    font-family: @font-family-secondary;
    font-weight: 700;
    font-size: 28px;
    line-height: 44px;
    color: @dark;

    .screen-lg({
      font-size: 20px;
      line-height: 28px;
    });
  }

  &__featured-facilities {
    margin-bottom: 124px;

    .screen-lg({
      margin-bottom: 72px;
    });
  }

  &__faqs {
    margin-bottom: 100px;

    .screen-lg({
      margin-bottom: 72px;
    });
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';