@import 'assets/less/variables';

.email-templates {
  &__tab {
    display: flex;
    align-items: center;

    span {
      margin-right: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: @gray-2;
      text-transform: capitalize;
    }

    svg {
      width: 26px;
      height: 26px;
    }
  }

  h3,
  p {
    font-size: 14px;
    line-height: 24px;
    color: @gray-4;
  }

  h3 {
    font-weight: 600;
    margin-bottom: 8px;
  }

  .ant-tabs {
    margin: 24px;
    border: @border-style-1;
    border-radius: @border-radius-5;
    background: #fff;
    padding: 24px;
  }

  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
    padding: unset;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    span {
      font-weight: 600;
      color: @primary;
    }

    svg {
      path {
        fill: @primary;
      }
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-left > .ant-tabs-content-holder {
    border: none;
  }

  .ant-tabs-left
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding-left: 147px;
    max-width: 877px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';