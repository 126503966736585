@import 'assets/less/variables';

.review-block {
  margin-bottom: 120px;

  .screen-lg({
        margin-bottom: 48px;
    });

  h2 {
    text-align: center;
    margin-bottom: 0;
  }

  &__subheader {
    text-align: center;
    margin-bottom: 24px;

    .screen-lg({
      margin-bottom: 48px;
    });
  }

  &__trustpilot-widget {
    margin-bottom: 75px;

    .screen-lg({
      margin-bottom: 45px;
    });
  }

  &__reviews-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 56px;

    .screen-lg({
      display: block;
      margin-bottom: 48px;
    });
  }

  .slick-dots-bottom {
    margin-bottom: 0;
    bottom: 0;
  }

  .slick-dots li {
    width: 8px !important;
    margin: 0 8px;

    button {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: @gray-2;
      opacity: unset;
    }
  }

  .slick-dots li.slick-active button {
    background-color: @primary;
  }

  &__button {
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 48px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';