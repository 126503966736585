@import 'assets/less/variables';

.talent-vacancy {
  background-color: @gray-6;
  padding-bottom: 24px;

  &__content-wrapper {
    max-width: 1138px;
    margin: 0 auto;

    .screen-lg({
      padding: 0 16px;
    });
  }

  &__back-button {
    margin: 16px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: @gray-3;
    transition: unset;

    svg {
      path {
        fill: @gray-3;
      }
      margin-right: 10.75px;
    }

    &:hover {
      color: @primary;
      svg {
        path {
          fill: @primary;
        }
      }
    }
  }

  &__back-to-top {
    display: none;

    .screen-lg({
      margin-top: 28px;
      display: flex;
      justify-content: flex-end;

      .ant-btn {
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
      }

      svg {
        width: 20px;
        height: 18px;
        margin-right: 12px;
      }

      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: @gray-4;
      }
    });
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';