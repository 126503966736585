@import 'assets/less/variables';

.team-member {
  &__tab {
    .ant-tabs-nav {
      background: #fff;
      padding-left: 24px;
      margin: 0;
    }

    .ant-tabs-content {
      padding: 16px 24px;
    }

    .ant-tabs-tab-btn {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 600;
      color: @gray-4;
    }
  }

  &__table {
    border-radius: @border-radius-2;
    border: 1px solid @primary-light-2;
    background-color: #fff;
    padding-bottom: 24px;
    overflow: hidden;

    .ant-table-thead {
      .ant-table-cell {
        background: #fff;
        font-size: 12px;
        font-weight: 700;
        color: @gray-1;
        text-transform: uppercase;
      }
      .ant-table-cell::before {
        display: none;
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        height: 48px;
        font-size: 14px;
      }
    }

    .ant-table-pagination {
      margin-right: 24px !important;
    }
  }

  &__more-btn {
    display: flex;
    align-items: center;
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 200px);
    max-width: 560px;
    margin: auto;
    text-align: center;
  }

  &__delete-modal {
    .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
      border: none;
      padding-left: 0;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';