@import 'assets/less/variables';

.profile-progress {
  &__dropdown {
    padding: 0;
  }

  &__dropdown-item:hover {
    background-color: @primary-light;
  }

  &__header-title {
    display: inline-flex;
    align-items: center;
    color: @black;
    font-weight: 600;
    font-size: 16px;
  }

  &__header-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__progress {
    display: flex;
    flex-direction: row;
  }

  &__diagram {
    margin-right: 8px;
  }

  &__dropdown-wrapper {
    .screen-sm({
      display: flex;
      justify-content: flex-end;
    });
  }

  &__dropdown-text {
    font-size: 14px;
    line-height: 40px;
    color: #42526e;

    &--active {
      color: @primary;
    }

    .screen-xs({
      font-size: 11px
    });
  }

  &__selected-value {
    color: @primary;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    svg path {
      fill: @primary;
    }

    span {
      margin: 0 4px;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';