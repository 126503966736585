@import 'assets/less/variables';

.home-header {
  background-color: #ffffff;
  border-bottom: 1px solid @gray-7;

  &__wrapper {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1134px;
    height: 80px;

    .screen-lg({
      padding: 0 32px;
      height: 66px;
    });
  }

  &__logo {
    width: 140px;
    height: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  &__mobile-logo {
    width: 24px;
    height: 24px;
  }

  &__navigation-bar {
    .ant-dropdown-trigger,
    a {
      margin: 0 32px 0 0;
      color: @gray-4;
      transition: color 0.3s;
      cursor: pointer;

      svg {
        margin: 0 0 -3px 12px;
      }

      &:hover {
        color: @primary;
        path {
          fill: @primary;
        }
      }
    }
    a:last-child {
      margin-right: 0;
    }
  }

  .ant-dropdown-menu,
  .ant-dropdown-menu-submenu-popup {
    border-radius: @border-radius-2;
    margin-top: 12px;
    padding: 15px 12px 8px 12px;

    a,
    .ant-dropdown-menu-submenu-title {
      display: block;
      padding: 5px 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: @gray-4;
      margin: 0 32px 12px 0 !important;

      &:hover {
        background: unset;
        color: @primary;
      }
    }
    .ant-dropdown-menu-submenu-expand-icon {
      top: 3px;
    }
  }

  &__button-link {
    width: 95px;
    color: @primary;

    .screen-lg({
      width: 100%;
    });
  }

  &__button-primary {
    min-width: 140px;
    height: 48px;
    margin-left: 8px;

    .screen-lg({
      min-width: 100%;
      margin-left: 0;
    });
  }

  &__button-menu {
    width: 38px;
    height: 38px;
    padding: 0;
    border: none;
    outline: none;
    background: none;
  }

  &__drawer {
    &__logo {
      &__wrapper {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: auto;
      }
    }

    .ant-drawer-header {
      padding: 24px;
      border-bottom: none;
    }

    .ant-drawer-title {
      svg {
        width: 132px;
        height: 18px;
      }
    }

    .ant-drawer-body {
      padding: 0 24px 24px 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .ant-menu-inline {
      border: none;
      background: none;
    }

    .home-header__side-menu-section a,
    .ant-menu-submenu-title {
      display: flex;
      align-items: center;
      padding: 0 !important;
      font-weight: 400;
      font-size: 16px;
      line-height: 40px;
      color: @gray-4;
      border: none;
      margin: 0 0 12px 0;

      &:hover {
        color: @gray-4;
      }

      svg {
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }
    }

    .ant-menu-submenu-arrow {
      &::before,
      &::after {
        color: @gray-4;
      }
    }
  }

  &__active {
    font-weight: 600 !important;
    color: @primary !important;

    svg {
      path {
        fill: @primary;
      }
    }
  }

  &__indent {
    margin-left: 24px !important;
  }
}

.ant-dropdown-menu-sub {
  max-height: 540px;
  border-radius: @border-radius-2;
  padding: 15px 12px;
  overflow: hidden auto;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: @bgc-primary;
    border-top-right-radius: @border-radius-2;
    border-bottom-right-radius: @border-radius-2;
  }
  &::-webkit-scrollbar-thumb {
    background: @gray-2;
    border-radius: @border-radius-3;
  }

  a {
    display: block;
    padding: 5px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: @gray-4;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: unset;
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';