@import 'assets/less/variables';

.facility-card {
  &__wrapper {
    width: 348px;
    padding: 24px;
    background: @light;
    border: 1px solid #f4f5f7;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: @border-radius-5;
  }

  &__facility-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: @dark;
    height: 56px;
  }

  &__record {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;
  }

  &__icon {
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }

  &__text {
    font-size: 14px;
    line-height: 24px;
    color: @gray-1;
  }

  &__view-btn {
    margin-top: 8px;
    width: 100%;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';