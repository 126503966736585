@import 'assets/less/variables';

.admin-layout {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  overflow: hidden;
}

.admin-tool {
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  &__filter {
    background: #ffffff;
    box-shadow: -7px 0 12px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    width: 400px;
    padding: 24px;

    .ant-select-selector {
      border: 1px solid #d9d9d9 !important;
    }
    .ant-form-item-has-success {
      input {
        border: 1px solid #d9d9d9 !important;
      }
    }
  }

  &__count {
    margin-left: 5px;
    background: @primary;
    color: #fff;
    padding: 2px 6px;
    border-radius: 50%;
    font-size: 10px;
  }

  &__filter-btn {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 6px;
    color: @gray-4;
    box-shadow: none;
    svg {
      margin-right: 10px;
      path {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
    &:focus,
    &:hover {
      background: @primary-light-4;
      svg {
        path {
          fill: @primary;
        }
      }
    }
  }

  &__filter-btn-active {
    background: @primary-light-4;
    color: @primary;
    svg {
      path {
        fill: @primary;
      }
    }
  }
}

.ant-layout-sider {
  background: #f9fafb;
  box-shadow: 0px -4px 9px rgba(0, 0, 0, 0.12);
}

.site-layout {
  background: #fff;
  margin-left: 250px;
}
.site-layout-content {
  padding: 32px;
  text-align: left;
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';