@import 'assets/less/variables';
@import 'assets/less/colors';

.advantages-block {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  gap: 150px;
  padding: 64px 0;
  margin-bottom: 32px;

  .screen-lg({
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 40px;
    flex-flow: column;
    gap: 32px;
  });

  &__how-it-works {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__title {
      color: @grey-900;
      font-size: 40px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -1px;
      text-transform: capitalize;
      margin: 0;

      .screen-lg({
        font-size: 32px;
        line-height: 156%;
      });
    }
    &__subtitle {
      color: @grey-800;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 148%;
      margin: 0;

      .screen-lg({
        margin-bottom: 12px;
      });
    }

    &__video {
      .screen-lg({
        width: 343px;
      });
    }
  }

  &__benefits {
    display: flex;
    flex-direction: column;
    gap: 56px;
    flex: 0 1 50%;

    .screen-lg({
      gap: 40px;
    });

    &__description {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &__title {
        color: @grey-900;
        font-size: 40px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -1px;
        text-transform: capitalize;
        margin: 0;

        .screen-lg({
          font-size: 32px;
          line-height: 156%;
        });
      }
      &__subtitle {
        color: @grey-800;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 148%;
        margin: 0;
      }
    }

    &__list {
      width: 100%;
      display: flex;
      flex-flow: column;
      gap: 56px;

      .screen-lg({
        gap: 40px;
      });

      .screen-lg({
        flex-direction: column;
        justify-content: unset;
        align-items: center;
      });

      &__item {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 16px;

        &__icon {
          flex: 0 0 56px;
        }

        &__description {
          display: flex;
          flex-flow: column;
          gap: 4px;

          &__title {
            color: @grey-900;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 156%;
            margin: 0;
          }
          &__text {
            color: @grey-700;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 148%;
            margin: 0;
          }
        }
      }

    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';