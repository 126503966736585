@import 'assets/less/variables';

.estimator-form {
  border-radius: @border-radius-5;
  border: @border-style-1;
  min-width: 772px;
  height: fit-content;
  background-color: #ffffff;
  padding: 24px;

  .screen-lg({
    min-width: 100%;
    padding: 16px;
  });

  h2 {
    margin-bottom: 24px;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;

    .screen-lg({
      flex-direction: column;
    });
  }

  &__form-wrapper {
    display: flex;
    width: 100%;

    .screen-lg({
      flex-direction: column;
    });

    > form {
      padding-left: 16px;
    }

    > form, .estimator-form__estimate {
      flex-basis: 50%;

      .screen-lg({
        flex-basis: unset;
      });
    }
  }

  &__estimate {
    display: flex;
    flex-direction: column;
    align-items: center;

    .screen-lg({
      margin-top: 32px;
    });

    h2 {
      text-align: center;
      margin-bottom: 10px;
    }

    div {
      border-radius: @border-radius-2;
      background: #F6FBFF;
      margin-bottom: 10px;
      padding: 16px;
    }

    p {
      text-align: center;
      margin: 0 auto;
    }

    p:first-child {
      font-weight: 700;
      line-height: 44px;
      font-size: 28px;
      color: @primary;
    }

    p:last-child {
      font-weight: 400;
      line-height: 20px;
      font-size: 12px;
      color: #234361;
    }
  }

  &__submission-request {
    color: #42526e;
    padding: 0 20px;
    margin-bottom: 10px !important;

    ~ a {
      font-weight: 600;
      font-size: 16px;
      color: #3b9cfe;
    }
  }

  &__info-message {
    border-radius: @border-radius-2;
    background-color: @primary;
    padding: 16px;

    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #fff;

    margin-bottom: 32px;
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';