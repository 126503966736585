@import 'assets/less/variables';

.stub-card {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  text-align: center;

  &__title {
    line-height: 1.2;
  }

  &__subtitle {
    margin-bottom: 16px;
  }

  &__button {
    width: 100%;
    max-width: 224px;
  }

  .screen-sm({
    min-height: 560px;
  });
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';