@import 'assets/less/variables';

.vacancies-card {
  width: calc(100% - (288px + 48px));

  .screen-lg({
      width: 100%;
    });

  &__vacancy {
    border-radius: @border-radius-2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    .screen-lg({
      flex-direction: column;
    });

    &:hover {
      background-color: #f6fbff;
    }

    div:first-child {
      span {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: @gray-1;
        margin-right: 16px;

        .screen-lg({
          display: block;
          margin-right: unset;
          text-align: center;
        });
      }
      span:last-child {
        margin-right: unset;

        .screen-lg({
          text-align: center;
          display: block;
        });
      }
    }

    a {
      display: block;
      border-radius: @border-radius-3;
      padding: 12px;
      width: 143px;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      &:hover {
        color: #fff;
        background: @primary;
      }
    }
  }
}

@primary-color: #3b9cfe;@text-color: #091828;@black: #091828;@heading-color: #091828;@border-radius-base: 4px;@btn-border-radius-base: 4px;@btn-height-base: 40px;@btn-font-weight: 600;@btn-shadow: none;@btn-text-shadow: none;@btn-primary-shadow: none;@label-color: #42526e;@form-item-label-font-size: 14px;@form-vertical-label-padding: 0 0 4px;@input-height-base: 40px;@input-placeholder-color: #a7aeba;@font-size-base: 16px;@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
 sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';